import { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Line } from "react-chartjs-2";
import { CommonUtils } from "../common_utils";

interface propsType {
  dataRows: any;
  chartTitle: any;
  labelTitles: any;
  xName: string;
  yName: string;
  lineColors: any;
  backgroundColors: any;
  legend: any;
}

interface IGridCols {
  label: string;
  value: number;
}

Chart.register(CategoryScale);

const cUtils = new CommonUtils();

const LineChart = (props: propsType) => {
  const convertTime = (input: string) => {
    return input.substring(8, 10) + ":" + input.substring(10, 12);
  };

  const [chartData, setChartData] = useState<any>({});
  const [stepSize, setStepSize] = useState(10);

  useEffect(() => {
    if (props.dataRows.length > 0) {
      let newDataRows = [...props.dataRows];
      const labels: any = newDataRows[0].map((data: IGridCols) => convertTime(data.label));
      const datasets: any = [];
      // eslint-disable-next-line array-callback-return
      let maxValue = 0;
      // eslint-disable-next-line array-callback-return
      newDataRows.map((data: any, index: number) => {
        const temp: any = {
          label: props.labelTitles[index],
          data: data.map((col: IGridCols) => {
            if (col.value > maxValue) maxValue = col.value;
            return col.value;
          }),
          borderColor: props.lineColors[index],
          backgroundColor: props.backgroundColors[index],
        };
        datasets.push(temp);
      });
      const length = maxValue.toString().length;
      setStepSize(length > 5 ? 10000 : length > 4 ? 1000 : length > 3 ? 100 : length > 2 ? 10 : 1);
      let data: any = {
        labels: labels,
        datasets: datasets,
      };

      // console.log(data);
      setChartData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataRows]);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setChartData({});
    };
  }, []);

  return (
    <div className="chart-root">
      {!cUtils.isEmptyObj(chartData) && (
        <Line
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: props.chartTitle.display,
                text: props.chartTitle.text,
              },
              legend: {
                display: props.legend.display,
                position: props.legend.position,
              },
            },
            interaction: {
              intersect: false,
            },
            scales: {
              x: {
                display: true,
                title: {
                  display: false,
                  text: props.xName,
                },
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: props.yName,
                },
                beginAtZero: true,
                ticks: {
                  stepSize: stepSize,
                },
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default LineChart;
