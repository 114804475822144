import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { useWindowSize } from "../../../utils/useWindowSize";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import IconButton from "@mui/material/IconButton";
import BlockIcon from "@mui/icons-material/Block";
import CloseIcon from "@mui/icons-material/Close";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import Divider from "@mui/material/Divider";
import Slider from "@mui/material/Slider";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModeIcon from "@mui/icons-material/Mode";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";

import { ListChildComponentProps, VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { HttpAdminApi } from "../../../interface/admin-api";
import { HttpAdminIotApi } from "../../../interface/admin-iot-rest-api";
import { userState, appConfig } from "../../../interface/MainInterface";
import { CommonUtils } from "../../../utils/common_utils";
import * as ChatConstant from "./ChatConstant";

import ChatBlock from "./ChatBlock";
import ChatUpdate from "./ChatUpdate";
import { Stack, TextField } from "@mui/material";

import LoadingCircle from "../../../utils/LoadingCircle";
import { useInterval } from "../../../utils/UseInterval";

import "./LiveChatModule.css";
import dayjs from "dayjs";

interface propsType {
  channelInfo: any;
  userState: userState;
  sendIoTMessage: any;
  callback: any;
}

let quoteBoxCss = {
  position: "absolute",
  bottom: 0,
  width: "100%",
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
};

interface chatSendForm {
  msg: string;
}

const adminApi = new HttpAdminApi();
const adminIotApi = new HttpAdminIotApi();

const LiveChatModule = (props: propsType, ref: any) => {
  const windowSize = useWindowSize();
  const cUtils = new CommonUtils();

  const [loading, setLoading] = useState(false);

  const inputChatRef: any = useRef();
  const inputMsgRef = useRef<any>();

  const chatInnerRef = useRef<HTMLDivElement>();
  const listRef = useRef<any>(null); // Ref for chatting list
  const rowHeights = useRef<any>({}); // Array for set dynamic height foreach message contents

  const [chatInit, setChatInit] = useState(false);
  const [messages, setMessages] = useState<any>([]);
  const [nextToken, setNextToken] = useState(undefined);
  const [needScrollEnd, setNeedScrollEnd] = useState<Boolean>(true);
  const [btnNewMsgBoxStyle, setBtnNewMsgBoxStyle] = useState({
    display: "none",
  });
  const [managerCampaignText, setManagerCampaignText] = useState("");
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);

  // Ref for subscribe callback
  const messagesRef = useRef(messages);
  const chatBlockRef: any = useRef();
  const chatUpdateRef: any = useRef();

  const [quoteText, setQuoteText] = useState("");
  const [qMessageId, setQMessageId] = useState("");

  const [fontSize, setFontSize] = useState<number | Array<number> | undefined>(1.0);

  const [pool, setPool] = useState(false);

  useEffect(() => {
    if (windowSize.width < 768) {
      setFontSize(0.7);
    }
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setMessages([]);
      setNextToken(undefined);
      setNeedScrollEnd(true);
      setBtnNewMsgBoxStyle({ display: "none" });
      setManagerCampaignText("");
      setVisibleStartIndex(0);
      setQuoteText("");
      setQMessageId("");
      setFontSize(1.0);
      setLoading(false);
      setPool(false);
    };
  }, []);

  // subscript callback 연동용 Ref 최신화
  useEffect(() => {
    messagesRef.current = messages;
  });

  // 관리자 이벤트 수신시 Toast 메세지를 띄우주기
  useEffect(() => {
    if (managerCampaignText !== "") {
      setTimeout(() => {
        setManagerCampaignText("");
      }, 2000);
    }
  }, [managerCampaignText]);

  // 채팅창 스크롤 제일 아래로 이벤트 처리
  useEffect(() => {
    if (messages.length > 0) {
      if (needScrollEnd) {
        setBtnNewMsgBoxStyle({ display: "none" });
        scrollToBottom();
      } else {
        //if (!props.hidden_menu)
        setBtnNewMsgBoxStyle({ display: "inline" });
      }
    }
  }, [messages, needScrollEnd]);

  // 최초 채널 입장시 기존 차임 메시지 조회(현재 최대 50건)
  const getListChannelMessages = async (channelArn: string) => {
    if (channelArn === undefined) return;
    const param: any = {
      command: "list_chat_history",
      channel_arn: channelArn,
      next_token: nextToken,
      list_mode: appConfig.listChannelMode,
      broad_seq: props.channelInfo.broad_seq,
    };

    await adminApi.post(param).then((result: any) => {
      if (result.code === "200") {
        setMessages(result.response.chat_history.Messages);
        setChatInit(true);
      }
    });
  };

  useEffect(() => {
    console.log("messages : ", messages);
  }, [messages]);

  const pollChannelMessages = async (channel_arn: string) => {
    // console.log("11");
    const param: any = {
      command: "list_chat_limit_history",
      channel_arn: channel_arn,
      next_token: nextToken,
      list_mode: appConfig.listChannelMode,
      limit: 30,
      broad_seq: props.channelInfo.broad_seq,
    };

    await adminApi.post(param).then((result: any) => {
      if (result.code === "200") {
        for (const msg of result.response.chat_history.Messages) {
          msg.ChannelArn = channel_arn;
          processChannelMessage(msg);
        }
      }
    });
  };

  const delayPool: number = 2000; // 2초마다 변경
  useInterval(
    () => {
      pollChannelMessages(props.channelInfo.chime_channel_arn);
    },
    pool ? delayPool : null
  );

  useEffect(() => {
    if (
      props.channelInfo?.broad_status === "STOP" ||
      props.channelInfo?.broad_status === "CREATE" ||
      props.channelInfo?.broad_status === "VOD"
    ) {
      setPool(false);
      setTimeout(() => {
        setNeedScrollEnd(true);
        setBtnNewMsgBoxStyle({ display: "none" }); // 최신메세지 버튼
      }, 500);
    } else {
      if (chatInit) {
        setTimeout(() => {
          setNeedScrollEnd(true);
          setBtnNewMsgBoxStyle({ display: "none" }); // 최신메세지 버튼
          setPool(true);
        }, 500);
      } else setPool(false);
    }
  }, [props.channelInfo, chatInit]);

  // 차임 메시지 도착(실시간)시 처리용 프로세스
  const processChannelMessage = async (message: any) => {
    const promise = Promise.resolve(message);
    const newMessage = await promise.then((m) => m);

    if (newMessage.ChannelArn !== props.channelInfo.chime_channel_arn) return;
    let isDuplicate = false;
    messagesRef.current?.forEach((m: any, i: number, self: any) => {
      if ((m.response?.MessageId || m.MessageId) === newMessage.MessageId) {
        isDuplicate = true;
        self[i] = newMessage;
      }
    });

    let newMessages: any = [...messagesRef.current];
    if (!isDuplicate && newMessage.Persistence === ChatConstant.Persistence.PERSISTENT) {
      newMessages = [...newMessages, newMessage];
      setMessages(newMessages);
    }
  };

  const fncQuote = (msg: any) => {
    setQuoteText(`(${msg.Sender.Name}) ${msg.Content}`);
    setQMessageId(msg.MessageId);
    inputMsgRef.current?.focus();
  };

  const fncQuoteOnly = (msg: any) => {
    setQuoteText(`(${msg.Sender.Name}) ${msg.Content}`);
    setQMessageId("");
    inputMsgRef.current?.focus();
  };

  const fncBlock = async (message: any) => {
    // Speed Dial open
    chatBlockRef.current?.open(message);
  };

  const fncChatUpdate = async (msg: any) => {
    chatUpdateRef.current?.open(msg);
  };

  const fncResetQuote = () => {
    setQuoteText((quoteText) => "");
    setQMessageId((qMessageId) => "");
  };

  const resetMessages = async () => {
    if (window.confirm("채팅 메세지를 모두 삭제하시겠습니까?")) {
      setLoading(true);
      const param: any = {
        command: "delete_chat_history",
        channel_arn: props.channelInfo.chime_channel_arn,
        broad_seq: props.channelInfo.broad_seq,
      };
      await adminApi.post(param);
      setLoading(false);
      window.location.reload();
    }
  };

  const downloadMessages = async () => {
    // sheet header
    let chatHistory: any = [["순번", "채팅일시", "작성자", "내용"]];
    // 각 행 순번 만들기
    let chatIndex = 1;
    // 내용 생성
    for (const message of messages) {
      const detail = [
        chatIndex,
        dayjs(String(message.CreatedTimestamp)).format("YYYY-MM-DD HH:mm:ss"),
        message.Sender.Name,
        message.Content,
      ];
      chatHistory = [...chatHistory, detail];
      chatIndex += 1;
    }
    // 컬럼 넓이
    const colWidth = [{ wpx: 40 }, { wpx: 150 }, { wpx: 130 }, { wpx: 800 }];
    const sheetName = "채팅내역";
    // 파일명
    const fileName =
      dayjs(String(props.channelInfo.broad_start_tm)).format("YYYY-MM-DD HHmm") + "_채팅_" + props.channelInfo.broad_title + ".xlsx";
    cUtils.downloadExcel(chatHistory, colWidth, sheetName, fileName);
  };

  const fncUpdate = async (msg: any) => {
    setLoading(true);
    let updateParam: any = msg;
    updateParam.command = "update_chat_history";
    updateParam.broad_seq = props.channelInfo.broad_seq;

    await adminApi.post(updateParam);
    const topic = `live/${props.channelInfo.broad_seq}/payload`;
    const chatPayload = {
      topic: "chat/message",
      ChannelArn: props.channelInfo.chime_channel_arn,
      Content: msg.Content,
      CreatedTimestamp: "",
      LastUpdatedTimestamp: "",
      MessageId: msg.MessageId,
      Persistence: "PERSISTENT",
      Redacted: false,
      Sender: {
        Arn: props.channelInfo.chime_admin_arn,
        Name: props.userState.name,
      },
      Status: {
        Value: "SENT",
      },
      Type: "STANDARD",
    };
    // 채팅 금지 확인 필요
    const param: any = {
      topic: topic,
      payload: chatPayload,
    };

    await adminIotApi.send_iot_message(param);
    setLoading(false);

    setNeedScrollEnd(true);
    setBtnNewMsgBoxStyle({ display: "none" }); // 최신메세지 버튼
  };

  // 채팅 메시지 전송
  const sendMessage = async (msg: string, meta: string) => {
    const origin_msg = msg;
    const qMsgId = qMessageId;
    // 답글이 있으면 답글을 신규 컨텐츠에 추가
    if (quoteText !== "") {
      msg = quoteText + appConfig.quote_separator + msg;
    }

    fncResetQuote();

    const msgId = uuidv4();
    const topic = `live/${props.channelInfo.broad_seq}/payload`;
    const chatPayload = {
      command: "send_chat_history",
      topic: "chat/message",
      ChannelArn: props.channelInfo.chime_channel_arn,
      Content: msg,
      CreatedTimestamp: "",
      LastUpdatedTimestamp: "",
      MessageId: msgId,
      Persistence: "PERSISTENT",
      Redacted: false,
      Sender: {
        Arn: props.channelInfo.chime_admin_arn,
        Name: props.userState.name,
      },
      Status: {
        Value: "SENT",
      },
      Type: "STANDARD",
    };
    // 채팅 금지 확인 필요
    const param: any = {
      topic: topic,
      payload: chatPayload,
    };

    adminIotApi.send_iot_message(param);
    await adminApi.post(chatPayload);
    if (quoteText !== "" && qMsgId !== "") {
      const qnaParam: any = {
        command: "send_qna_message",
        message_info: {
          broad_seq: props.channelInfo.broad_seq,
          channel_arn: props.channelInfo.chime_channel_arn,
          q_message_id: qMsgId,
          a_message_id: msgId,
          origin_msg: origin_msg,
          user_id: props.channelInfo.chime_admin_arn,
        },
      };
      const resultQna = await adminApi.post(qnaParam);
      props.sendIoTMessage({
        topic: "live/metadata",
        message: "qna",
        q: resultQna.response.q_message_info.content,
        qSender: resultQna.response.q_message_info.nick_name,
        a: origin_msg,
        aSender: resultQna.response.a_message_info.nick_name,
      });
    }

    setNeedScrollEnd(true);
    setBtnNewMsgBoxStyle({ display: "none" }); // 최신메세지 버튼
  };

  // 채팅창 ListItem의 높이를 동적 처리하기 위한 기능
  function getRowHeight(index: number) {
    return rowHeights.current[index] + 8 || 36;
  }

  // 각 row별 높이를 저장하기 위한 기능
  function setRowHeight(index: any, size: any) {
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  // 채팅 메시지별 Row 그리는 기능(동적 높이 구현을 위한 기능 포함)
  function RenderRow(listProps: ListChildComponentProps) {
    const { index, style } = listProps;
    const newStyle = {
      left: "0px",
      height: style.height,
      width: "100%",
    };

    const rowRef = useRef<any>({});

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current?.clientHeight);
      }
    }, [rowRef]);

    return (
      <ListItem sx={{ padding: "8px 20px", userSelect: "text" }} style={visibleStartIndex > 0 ? style : newStyle} key={index}>
        <ListItemText
          primary={
            <Typography ref={rowRef} component="div" className="chat-list-body">
              <Box
                sx={{
                  width: "100%",
                  color: "black",
                  display: "inline-flex",
                  fontSize: fontSize + "rem",
                  ...(messages[index].Sender.Arn === props.channelInfo.chime_admin_arn
                    ? { justifyContent: "flex-end" }
                    : { justifyContent: "flex-start" }),
                }}
              >
                <Stack direction="column" spacing={1}>
                  {messages[index].Sender.Arn !== props.channelInfo.chime_admin_arn &&
                    (messages[index].Sender.Name !== messages[index - 1]?.Sender.Name ||
                      messages[index].Sender.Arn !== messages[index - 1]?.Sender.Arn) && (
                      <span style={{ maxWidth: "10rem", display: "inline-block", textOverflow: "ellipsis", overflow: "hidden" }}>
                        {cUtils.maskingChar(messages[index].Sender.Name)}
                      </span>
                    )}
                  <Stack direction="row" spacing={1}>
                    {messages[index].Sender.Arn === props.channelInfo.chime_admin_arn &&
                      props.channelInfo.broad_status !== "VOD" &&
                      props.channelInfo.broad_status !== "STOP" && (
                        <Stack direction="row" spacing={1} sx={{ justifyContent: "flex-start" }}>
                          <div style={{ alignSelf: "center", marginLeft: "10px" }}>
                            <Tooltip title="채팅수정">
                              <IconButton sx={{ padding: 0 }} onClick={() => fncChatUpdate(messages[index])}>
                                <ModeIcon sx={{ color: "black" }} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </Stack>
                      )}
                    <div
                      className={messages[index].Sender.Arn === props.channelInfo.chime_admin_arn ? "speech-bubble-me" : "speech-bubble"}
                    >
                      {messages[index].Content.split(appConfig.quote_separator).map((line: string, idx: number) => {
                        const repleLength = messages[index].Content.split(appConfig.quote_separator).length;
                        let styleCss: any = {
                          wordBreak: "break-all",
                          whiteSpace: "pre-line",
                        };
                        if (idx !== repleLength - 1) styleCss.color = "#999999";
                        if (idx === 0) {
                          if (messages[index].Metadata !== undefined && messages[index].Metadata === "chat_blocked") {
                            styleCss.color = "cadetblue";
                            // 차단된 메세지
                            return (
                              <del key={index} style={styleCss}>
                                {"[차단]"}
                                {line}
                              </del>
                            );
                          } else if (
                            messages[index].Sender.Arn === props.channelInfo.chime_admin_arn &&
                            messages[index].Metadata !== undefined &&
                            messages[index].Metadata !== "" &&
                            messages[index].Metadata !== "chat_blocked"
                          ) {
                            // 어드민 일때 당첨자 마스킹 해제후 보이게 처리
                            return (
                              <span key={index} style={styleCss}>
                                {messages[index].Metadata}
                              </span>
                            );
                          } else {
                            return (
                              <span key={index} style={styleCss}>
                                {line}
                              </span>
                            );
                          }
                        } else {
                          styleCss.color = "#59f4f4";
                          return (
                            <span key={index + "-" + idx} style={styleCss}>
                              <Divider sx={{ borderColor: "#fff" }} />
                              {appConfig.quote_separator + " " + line}
                            </span>
                          );
                        }
                      })}
                    </div>
                    {messages[index].Metadata !== undefined && messages[index].Metadata === "chat_blocked" ? (
                      <></>
                    ) : (
                      messages[index].Sender.Arn !== props.channelInfo.chime_admin_arn &&
                      props.channelInfo.broad_status !== "VOD" &&
                      props.channelInfo.broad_status !== "STOP" && (
                        <Stack direction="row" spacing={1} sx={{ justifyContent: "flex-end" }}>
                          <div style={{ alignSelf: "center", marginLeft: "10px" }}>
                            <Tooltip title="Q&A답글">
                              <IconButton sx={{ padding: 0 }} onClick={() => fncQuote(messages[index])}>
                                <MapsUgcIcon sx={{ color: "black" }} />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <div style={{ alignSelf: "center", marginLeft: "10px" }}>
                            <Tooltip title="채팅차단">
                              <IconButton sx={{ padding: 0 }} onClick={() => fncBlock(messages[index])}>
                                <BlockIcon sx={{ color: "red" }} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </Stack>
                      )
                    )}
                  </Stack>
                </Stack>
              </Box>
            </Typography>
          }
        />
      </ListItem>
    );
  }

  // 스크롤에 따라서 최신 메시지, 스크롤 자동 하단 등 처리를 위한 이벤트
  const evtItemRendered = (e: any) => {
    if (chatInnerRef.current) chatInnerRef.current.style.marginTop = "auto";
    setVisibleStartIndex((visibleStartIndex) => e?.visibleStartIndex);
    if (e?.visibleStopIndex > 0 && messages.length > 0) {
      if (e.visibleStopIndex >= messages.length - 1) {
        setNeedScrollEnd(true);
        setBtnNewMsgBoxStyle({ display: "none" });
      } else {
        setNeedScrollEnd(false);
      }
    }
  };

  // 채팅창 내용 업데이트시 스크롤을 제일 아래로
  const scrollToBottom = () => {
    if (messages.length > 0) {
      listRef?.current?.scrollToItem(messages.length, "end");
    }
  };

  const callbackChatBlock = () => {};

  const handleTemperatureChange = (e: Event, newValue: number | number[]) => {
    setFontSize(newValue);
  };

  useEffect(() => {
    if (props.channelInfo.chime_channel_arn !== "") {
      setTimeout(() => {
        getListChannelMessages(props.channelInfo.chime_channel_arn);
      }, 500);
    }
  }, [props.channelInfo.chime_channel_arn]);

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    sendMessage,
    scrollToBottom,
    fncResetQuote,
    resetMessages,
    processChannelMessage,
  }));

  // Form for send message to chime channel
  const {
    register,
    setFocus,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<chatSendForm>();

  // 채팅 메시지 전송버튼 클릭
  const onSubmit: SubmitHandler<chatSendForm> = async (data) => {
    inputMsgRef.current.focus();
    reset({ msg: "" });
    await sendMessage(data.msg, "");
  };

  return (
    <>
      <Box
        id="admin-chat-root"
        component="div"
        sx={
          /* 메인화면에서 비디오영역 클릭시 메뉴 감추기 */
          {
            height: "100%",
            width: "100%",
          }
        }
        className="admin-chat-root"
      >
        <Stack direction={"column"} spacing={0} sx={{ width: "100%", height: "100%" }}>
          <Box sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}>
            <Stack
              direction={windowSize.width > 768 ? "row" : "column"}
              spacing={2}
              sx={{ width: "100%", justifyContent: "space-between" }}
            >
              <Stack direction={"row"} spacing={2}>
                <Button
                  variant="contained"
                  color="error"
                  size={windowSize.width > 768 ? "medium" : "small"}
                  onClick={() => resetMessages()}
                  startIcon={<DeleteForeverIcon />}
                >
                  채팅삭제
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size={windowSize.width > 768 ? "medium" : "small"}
                  onClick={() => downloadMessages()}
                  startIcon={<DownloadIcon />}
                >
                  채팅 다운로드
                </Button>
              </Stack>
              {windowSize.width > 768 && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Slider
                    value={fontSize}
                    onChange={handleTemperatureChange}
                    valueLabelDisplay="auto"
                    step={0.5}
                    min={1.0}
                    max={3.0}
                    sx={{ width: "100px" }}
                  />
                </Box>
              )}
            </Stack>
          </Box>
          <Divider />
          {/* 체팅창 영역 */}
          <Box
            component="div"
            sx={{
              width: "100%",
              height: "100%",
              position: "relative",
            }}
          >
            <Box sx={{ height: "100%", width: "100%" }}>
              <AutoSizer style={{ height: "100%", width: "100%" }}>
                {({ ...props }) => (
                  <VariableSizeList
                    height={props.height}
                    width={props.width}
                    itemSize={getRowHeight}
                    itemCount={messages.length}
                    overscanCount={5}
                    className="admin-chat-list"
                    innerRef={chatInnerRef}
                    ref={listRef}
                    onItemsRendered={evtItemRendered}
                  >
                    {RenderRow}
                  </VariableSizeList>
                )}
              </AutoSizer>
              {quoteText !== "" && (
                <Box className="quote-Container" sx={quoteBoxCss}>
                  <div style={{ flexGrow: 1 }}>{quoteText}</div>
                  <IconButton sx={{ padding: 0 }} onClick={fncResetQuote}>
                    <CloseIcon className="color-snow" />
                  </IconButton>
                </Box>
              )}
            </Box>

            {/* 체팅창 스크롤 위로갈때 스크롤 아래로 이동하는 버튼 */}
            <Box className="btn-admin-new-chat" sx={btnNewMsgBoxStyle}>
              <Button
                sx={{
                  backgroundColor: "#ff376d",
                  "&:hover": { backgroundColor: "#ff376d" },
                }}
                variant="contained"
                size="small"
                onClick={scrollToBottom}
                endIcon={<ArrowCircleDownIcon />}
              >
                <span>최신 채팅으로 이동</span>
              </Button>
            </Box>
          </Box>
          <Box
            id="admin-chat-input"
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
            }}
            ref={inputChatRef}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <TextField
              inputRef={inputMsgRef}
              inputProps={
                props.userState.id === ""
                  ? { readOnly: true, enterKeyHint: "Send", style: { padding: "0px 14px" } }
                  : { enterKeyHint: "Send", style: { padding: "0px 14px" } }
              }
              type="search"
              autoComplete="off"
              sx={{ width: "100%", color: "black", "&::placeholder": { color: "gray" } }}
              placeholder={props.userState.id === "" ? "로그인 후 채팅에 참여하세요." : "채팅 내용을 입력해주세요."}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === "Escape") fncResetQuote();
              }}
              {...register("msg", { required: true, maxLength: 3000 })}
            />
            <Button id="btn-admin-send-chat" type="submit" variant="text">
              보내기
            </Button>
          </Box>
        </Stack>
      </Box>
      <ChatBlock ref={chatBlockRef} broadSeq={props.channelInfo.broad_seq} callBack={callbackChatBlock} />
      <ChatUpdate ref={chatUpdateRef} updateChat={fncUpdate} />
      <LoadingCircle loading={loading} />
    </>
  );
};

export default forwardRef(LiveChatModule);
