import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { useWindowSize } from "../../../../utils/useWindowSize";

import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowSelectionModel,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiRef,
  useGridSelector,
} from "@mui/x-data-grid";
import { Box, Button, Pagination, Stack } from "@mui/material";
import CelebrationIcon from "@mui/icons-material/Celebration";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { userState } from "../../../../interface/MainInterface";
import { HttpAdminApi } from "../../../../interface/admin-api";
import LoadingCircle from "../../../../utils/LoadingCircle";
import Toast from "../../../../utils/Toast";
import dayjs from "dayjs";

interface propsType {
  userState: userState;
  channelInfo: any;
  callback: any;
}

const adminApi = new HttpAdminApi();

const pageSize: number = 5;

const LiveEventListModule = (props: propsType, ref: any) => {
  const windowSize = useWindowSize();
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const gridApi = useGridApiRef();
  // 이벤트 목록
  const [eventList, setEventList] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<GridRowSelectionModel>([]);
  const [selectedPage, setSelectedPage] = useState<GridPaginationModel>({ pageSize: pageSize, page: 0 });

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setEventList([]);
      setSelectedRow([]);
      setSelectedPage({ pageSize: pageSize, page: 0 });
    };
  }, []);

  const columns: GridColDef[] = [
    {
      field: "event_condition.title",
      headerName: "제목",
      flex: 1,
      minWidth: 400,
      headerAlign: "center",
      align: "left",
      renderCell: (param: GridRenderCellParams) => {
        return (
          <Box>
            <Stack direction={"row"} spacing={windowSize.width > 768 ? 2 : 1} sx={{ alignItems: "center" }}>
              <Button
                variant="outlined"
                size="small"
                color={param.row.game_status === "CREATE" ? "info" : param.row.game_status === "START" ? "error" : "inherit"}
              >
                {param.row.game_status === "CREATE" ? "진행전" : param.row.game_status === "START" ? "진행중" : "완료"}
              </Button>
              <Button
                variant="text"
                size="small"
                startIcon={
                  param.row.flag === "apply" ? (
                    <CelebrationIcon />
                  ) : param.row.flag === "authBuy" ? (
                    <ShoppingCartIcon />
                  ) : (
                    <DoNotDisturbIcon />
                  )
                }
                sx={{ color: "#000000DE" }}
              >
                {windowSize.width > 768
                  ? param.row.flag === "apply"
                    ? "추첨이벤트"
                    : param.row.flag === "authBuy"
                    ? "구매인증이벤트"
                    : "미분류"
                  : ""}
              </Button>
              <Box>
                {param.row.event_condition?.title
                  ? param.row.event_condition?.title
                  : `추첨이벤트-${dayjs(param.row.start_dtm).format("YYYY-MM-DD HH:mm:ss")}`}
              </Box>
            </Stack>
          </Box>
        );
      },
    },
    {
      field: "delete",
      headerName: "삭제",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (param: GridRenderCellParams) => {
        return (
          <Button
            variant="outlined"
            size="small"
            color="error"
            startIcon={<DeleteForeverIcon />}
            onClick={() => {
              delete_game(param.row);
            }}
            disabled={param.row.draw_result.length > 0 || param.row.game_status === "START"}
          >
            {"삭제"}
          </Button>
        );
      },
    },
  ];

  // 게임목록 조회
  const get_game_list = async () => {
    const param: any = {
      command: "get_game_list",
      broad_seq: props.channelInfo.broad_seq,
    };

    setIsLoading(true);
    const res = await adminApi.post(param);
    console.log("res : ", res);
    if (res.code === "200") {
      //진행중, 생성, 종료 순으로 정렬하기
      let ingList: any = [];
      let createList: any = [];
      let endList: any = [];
      for (const gameInfo of res.response.game_list) {
        if (gameInfo.game_status === "START") {
          ingList.push(gameInfo);
        } else if (gameInfo.game_status === "CREATE") {
          createList.push(gameInfo);
        } else {
          endList.push(gameInfo);
        }
      }
      setEventList([...ingList, ...createList, ...endList]);
      setSelectedPage({ pageSize: pageSize, page: 0 });
    } else {
      console.error("get_game_list : ", res.response.error_msg);
    }
    setIsLoading(false);
  };

  // 게임삭제
  const delete_game = async (_gameInfo: any) => {
    console.log("_gameInfo : ", _gameInfo);
    if (_gameInfo.game_status === "START") {
      toastRef.current?.toast("진행중인 이벤트를 삭제할 수 없습니다.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    if (!window.confirm("이벤트를 삭제하시겠습니까?\n삭제한 이벤트는 복구할 수 없습니다.")) return;

    const param: any = {
      command: "delete_game",
      pk: _gameInfo.pk,
      sk: _gameInfo.sk,
    };

    setIsLoading(true);
    const res = await adminApi.post(param);
    console.log("res:", res);
    if (res.code === "200") {
      if (res.response.result) {
        toastRef.current?.toast("이벤트를 삭제했습니다.", "success", 4000, {
          vertical: "top",
          horizontal: "center",
        });
        get_game_list();
      }
    } else {
      toastRef.current?.toast("이벤트 삭제에 실패했습니다.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      console.error("delete_game : ", res.response.error_msg);
    }
    setIsLoading(false);
  };

  function CustomPagination() {
    const page = useGridSelector(gridApi, gridPageSelector);
    const pageCount = useGridSelector(gridApi, gridPageCountSelector);
    return (
      <Box className="tenants-list-pagenation">
        <Pagination
          color="primary"
          shape="rounded"
          count={pageCount === 0 ? 1 : pageCount}
          page={page + 1}
          siblingCount={windowSize.width > 768 ? 10 : 0}
          onChange={(e, v) => gridApi.current.setPage(v - 1)}
        ></Pagination>
      </Box>
    );
  }

  useEffect(() => {
    if (props.channelInfo?.broad_seq !== undefined) get_game_list();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.channelInfo]);

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    get_game_list,
  }));

  return (
    <>
      <Box sx={{ width: "100%", height: 321 }}>
        <DataGrid
          apiRef={gridApi}
          rows={eventList}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: pageSize,
              },
            },
            sorting: {
              sortModel: [
                {
                  field: "range_num",
                  sort: "asc",
                },
              ],
            },
          }}
          slots={{ pagination: CustomPagination }}
          hideFooterSelectedRowCount
          getRowId={(row) => row.sk}
          rowSelectionModel={selectedRow}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRow(newRowSelectionModel);
          }}
          paginationModel={selectedPage}
          onPaginationModelChange={(newPagenationModel) => {
            setSelectedPage(newPagenationModel);
          }}
          rowHeight={42}
          onCellClick={(param: GridCellParams) => {
            props.callback({ command: "select_row", info: param.row });
          }}
        />
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default forwardRef(LiveEventListModule);
