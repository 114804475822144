import { useEffect, useRef, useState } from "react";
import OpenAI from "openai";

import { Box, Grid, Stack, Divider, Typography, Button, Paper, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { HttpMainApi } from "../../interface/main-api";

import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

interface propsType {}

const mainApi = new HttpMainApi();

const Test = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [text, setText] = useState("");

  const openai = new OpenAI({ apiKey: "sk-proj-Jr9ugN6J2EKG894eFj6hT3BlbkFJ3thLR4Nnre4qfccerX2A", dangerouslyAllowBrowser: true });

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setText("");
    };
  }, []);

  const streamRawAudio = async (reader: ReadableStreamDefaultReader<Uint8Array>, audioCtx: AudioContext) => {
    while (true) {
      const { done, value } = await reader.read();
      console.log("done : ", done);
      console.log("value: ", value);

      if (done) {
        break;
      }

      await playAudioData(value, audioCtx);
    }
  };

  const playAudioData = async (audioData: Uint8Array, audioContext: AudioContext) => {
    const pcmAudio: Float32Array = new Float32Array(audioData.buffer);
    const audioBuffer = audioContext.createBuffer(1, pcmAudio.length, 24000);
    audioBuffer.copyToChannel(pcmAudio, 0);
    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(audioContext.destination);
    source.start();
    await new Promise((resolve) => (source.onended = resolve));
  };

  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Stack direction={"column"} spacing={0}>
          <Box className="my-account-header-root">
            <Typography variant="h5" gutterBottom className="my-account-header-title">
              Test
            </Typography>
          </Box>
          <Divider />
          <Box className="my-account-content-root">
            <Box className="apikey-content-header">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography gutterBottom className="my-account-content-sub-title">
                      Text To Speech test
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                    <Button
                      variant="contained"
                      onClick={async () => {
                        const response = await openai.audio.speech.create({
                          model: "tts-1",
                          voice: "alloy",
                          input: "Today is a wonderful day to build something people love!",
                          response_format: "mp3",
                        });

                        console.log("response : ", response);

                        const blob = new Blob([await response.arrayBuffer()], { type: "audio/mpeg" });
                        const objectUrl = URL.createObjectURL(blob);

                        // Testing for now
                        const audio = new Audio(objectUrl);
                        audio.play();

                        // const stream = response.body?.getReader();
                        // const audioCtx = new AudioContext();
                        // if (stream) await streamRawAudio(stream, audioCtx);
                      }}
                    >
                      Test
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Paper sx={{ p: 3, borderRadius: "4px" }} elevation={3}>
                <Stack direction="column" spacing={2}>
                  <TextField
                    id="txtText"
                    value={text}
                    placeholder="Please input text for TTS"
                    variant="outlined"
                    autoFocus
                    fullWidth
                    required
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter", style: { padding: "12px 16px 10px 16px" } }}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        console.log("111");
                      }
                    }}
                  />
                </Stack>
              </Paper>
            </Box>
          </Box>
          <audio id="audio"></audio>
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default Test;
