import { useEffect, useState } from "react";
import { Box, Stack, Button, Typography, Drawer, TextField } from "@mui/material";
import { useWindowSize } from "../../../utils/useWindowSize";

import { CommonUtils } from "../../../utils/common_utils";
import { userState } from "../../../interface/MainInterface";
import AssignmentIcon from "@mui/icons-material/Assignment";

import { HttpAdminApi } from "../../../interface/admin-api";

import LoadingCircle from "../../../utils/LoadingCircle";

import "./NoticeManagement.css";

declare global {
  interface Window {
    IVSPlayer: any;
  }
}

export let player: any = null;

interface propsType {
  userState: userState;
  channelInfo: any;
  heigth: number;
  callback: any;
}

const adminApi = new HttpAdminApi();
const cUtils = new CommonUtils();

const NoticeManagementOld = (props: propsType) => {
  const windowSize = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [broadNotice, setBroadNotice] = useState("");

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setOpen(false);
      setBroadNotice("");
    };
  }, []);

  useEffect(() => {
    if (!cUtils.isEmptyObj(props.channelInfo)) {
      setBroadNotice(props.channelInfo.broad_notice);
    }
  }, [props.channelInfo]);

  const update_broad_notice = async () => {
    if (!window.confirm("공지사항을 수정하시겠습니까?")) return;

    const param: any = {
      command: "update_broad_notice",
      channel_pk: props.channelInfo.channel_pk,
      broad_seq: props.channelInfo.broad_seq,
      broad_notice: broadNotice,
    };
    const res = await adminApi.post(param);
    if (res.code === "200") {
      props.callback({ command: "update_broad_notice" });
      setOpen(false);
    }
  };

  return (
    <>
      <Box
        sx={{ cursor: "pointer", minWidth: "70px" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <Stack direction={"column"} spacing={0} sx={{ backgroundColor: "#1876d2", color: "#ffffff", borderRadius: "7px", p: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <AssignmentIcon />
          </Box>
          <Typography sx={{ fontSize: "14px", textAlign: "center" }}>공지사항</Typography>
        </Stack>
      </Box>
      <Drawer
        anchor={windowSize.width > 768 ? "right" : "top"}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={{ width: windowSize.width > 768 ? "500px" : "100vw" }}>
          <Box sx={{ p: 1 }}>
            <Stack direction={"column"} spacing={2}>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>{"공지사항 관리"}</Typography>
              <TextField
                id="txtNotice"
                multiline
                rows={windowSize.height > 800 ? 25 : 20}
                fullWidth
                value={broadNotice}
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter" }}
                onChange={(e) => {
                  setBroadNotice(e.target.value);
                }}
              />
              <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                <Stack direction={"row"} spacing={2}>
                  <Button variant="contained" onClick={update_broad_notice}>
                    수정
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    닫기
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Drawer>
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default NoticeManagementOld;
