import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import dayjs from "dayjs";
import { useInterval } from "../../../../utils/UseInterval";
import { useWindowSize } from "../../../../utils/useWindowSize";

import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowSelectionModel,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiRef,
  useGridSelector,
} from "@mui/x-data-grid";
import { Box, Button, Pagination, Typography } from "@mui/material";

import { userState } from "../../../../interface/MainInterface";
import { HttpAdminApi } from "../../../../interface/admin-api";
import LoadingCircle from "../../../../utils/LoadingCircle";
import Toast from "../../../../utils/Toast";

interface propsType {
  userState: userState;
  channelInfo: any;
  gameInfo: any;
  callback: any;
}

const adminApi = new HttpAdminApi();

const pageSize: number = 5;

const LiveEventApplyList = (props: propsType, ref: any) => {
  const windowSize = useWindowSize();
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const gridApi = useGridApiRef();
  // 이벤트 목록
  const [applyList, setApplyList] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<GridRowSelectionModel>([]);
  const [selectedPage, setSelectedPage] = useState<GridPaginationModel>({ pageSize: pageSize, page: 0 });

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setApplyList([]);
      setSelectedRow([]);
      setSelectedPage({ pageSize: pageSize, page: 0 });
    };
  }, []);

  const columns: GridColDef[] = [
    {
      field: "sk",
      headerName: "아이디",
      flex: 1,
      minWidth: 200,
      headerAlign: "left",
      align: "left",
      renderCell: (param: GridRenderCellParams) => {
        return <span>{<Typography className={param.row.except ? "line-through color-gray" : ""}>{param.row.sk}</Typography>}</span>;
      },
    },
    {
      field: "cust_no",
      headerName: "고객번호",
      flex: 1,
      minWidth: 200,
      headerAlign: "left",
      align: "left",
      renderCell: (param: GridRenderCellParams) => {
        return <span>{<Typography className={param.row.except ? "line-through color-gray" : ""}>{param.row.cust_no}</Typography>}</span>;
      },
    },
    {
      field: "apply_dtm",
      headerName: "응모시간",
      flex: 1,
      minWidth: 200,
      headerAlign: "left",
      align: "left",
      renderCell: (param: GridRenderCellParams) => {
        return (
          <span>
            {
              <Typography className={param.row.except ? "line-through color-gray" : ""}>
                {dayjs(param.row.apply_dtm).format("YYYY-MM-DD HH:mm:ss")}
              </Typography>
            }
          </span>
        );
      },
    },
    {
      field: "except",
      headerName: "",
      width: 120,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <Button
            variant={param.row.except ? "contained" : "outlined"}
            color={param.row.except ? "primary" : "error"}
            onClick={() => {
              // console.log(param.value);
              if (param.row.except) {
                except_winner(param.row, false);
              } else {
                except_winner(param.row, true);
              }
            }}
          >
            {param.row.except ? "초기화" : "대상 제외하기"}
          </Button>
        );
      },
    },
    {
      field: "accept",
      headerName: "",
      width: 120,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <Button
            variant={param.row.accept ? "contained" : "outlined"}
            color={param.row.accept ? "primary" : "error"}
            onClick={() => {
              // console.log(param.value);
              if (param.row.accept) {
                accept_winner(param.row, false);
              } else {
                accept_winner(param.row, true);
              }
            }}
          >
            {param.row.accept ? "초기화" : "수기 당첨자"}
          </Button>
        );
      },
    },
  ];

  function CustomPagination() {
    const page = useGridSelector(gridApi, gridPageSelector);
    const pageCount = useGridSelector(gridApi, gridPageCountSelector);
    return (
      <Box className="tenants-list-pagenation">
        <Pagination
          color="primary"
          shape="rounded"
          count={pageCount === 0 ? 1 : pageCount}
          page={page + 1}
          siblingCount={windowSize.width > 768 ? 10 : 0}
          onChange={(e, v) => gridApi.current.setPage(v - 1)}
        ></Pagination>
      </Box>
    );
  }

  // 추첨 대상에서 제외/해제
  const except_winner = async (param: any, flag: boolean) => {
    param.command = "except_winner";
    param.except = flag;
    param.accept = false;

    const res = await adminApi.post(param);
    if (res.code === "200") {
      get_game_apply_list();
    }
  };

  // 수기 당첨자 선정/해제
  const accept_winner = async (param: any, flag: boolean) => {
    param.command = "accept_winner";
    param.except = false;
    param.accept = flag;

    const res = await adminApi.post(param);
    if (res.code === "200") {
      get_game_apply_list();
    }
  };

  // 응모자 목록 조회
  const get_game_apply_list = async () => {
    const param: any = {
      command: "get_game_apply_list",
      game_seq: props.gameInfo.sk,
    };

    // setIsLoading(true);
    const res = await adminApi.post(param);
    console.log("get_game_apply_list res : ", res);
    if (res.code === "200") {
      setApplyList(res.response.apply_list);
      setSelectedPage({ pageSize: pageSize, page: 0 });
    } else {
      console.error("get_game_list : ", res.response.error_msg);
    }
    // setIsLoading(false);
  };

  // 추첨하기
  const draw_game = async () => {
    let target = [];
    let acceptResult: any = [];
    const param1: any = {
      command: "get_game_apply_list",
      game_seq: props.gameInfo.sk,
    };
    const resApply = await adminApi.post(param1);
    if (resApply.code === "200") {
      setApplyList(resApply.response.apply_list);
      // 당첨자 제외
      for (const user of resApply.response.apply_list) {
        if (user.except) continue; // 수기 당첨자 제외
        if (user.accept) {
          // 관리자 수기 당첨자 우선 추가
          acceptResult.push({
            user_id: user.sk,
            cust_no: user.cust_no,
            nick_name: user.nick_name,
            apply_dtm: user.apply_dtm,
            apply_info: user.apply_info,
          });
        } else {
          // 나머지 대상자 목록
          target.push({
            user_id: user.sk,
            cust_no: user.cust_no,
            nick_name: user.nick_name,
            apply_dtm: user.apply_dtm,
            apply_info: user.apply_info,
          });
        }
      }

      let drawNum: any = props.gameInfo.event_condition.draw_number;
      drawNum = drawNum - acceptResult.length; // 랜덤 추첨 인원에서 수기 당첨자 제외
      console.log("drawNum : ", drawNum);

      if (!window.confirm("게임 추첨을 하시겠습니까?")) return;
      // 추첨
      let drawResult: any = [...acceptResult]; // 수기 당첨자 우선 추가
      for (let i = 0; i < drawNum; i++) {
        let drawInfo = target.splice(Math.floor(Math.random() * target.length), 1)[0];
        if (drawInfo) drawResult.push(drawInfo);
      }

      const param: any = {
        command: "draw_game",
        pk: props.gameInfo.pk,
        sk: props.gameInfo.sk,
        user_id: props.userState.id,
        drawResult: drawResult,
      };

      const res = await adminApi.post(param);
      if (res.code === "200") {
        props.callback({ command: "draw_game", info: props.gameInfo, drawResult: drawResult });
      } else {
        toastRef.current?.toast("추첨 중 오류가 발생했습니다.", "error", 3000);
      }
    } else {
      console.error(resApply.response.error_msg);
    }
  };

  useEffect(() => {
    console.log("props.gameInfo : ", props.gameInfo);
    if (props.gameInfo?.sk !== undefined) get_game_apply_list();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.gameInfo]);

  const delay: number = 3000; // 5초마다 변경
  useInterval(
    () => {
      get_game_apply_list();
    },
    props.gameInfo.game_status === "START" ? delay : null
  );

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    get_game_apply_list,
    draw_game,
  }));

  return (
    <>
      <Box sx={{ width: "100%", height: props.gameInfo.event_condition.gift_image_url ? 580 : 321 }}>
        <DataGrid
          apiRef={gridApi}
          rows={applyList}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: pageSize,
              },
            },
            sorting: {
              sortModel: [
                {
                  field: "range_num",
                  sort: "asc",
                },
              ],
            },
          }}
          slots={{ pagination: CustomPagination }}
          hideFooterSelectedRowCount
          getRowId={(row) => row.sk}
          rowSelectionModel={selectedRow}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRow(newRowSelectionModel);
          }}
          paginationModel={selectedPage}
          onPaginationModelChange={(newPagenationModel) => {
            setSelectedPage(newPagenationModel);
          }}
          rowHeight={42}
          onCellClick={(param: GridCellParams) => {
            props.callback({ command: "select_row", info: param.row });
          }}
        />
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default forwardRef(LiveEventApplyList);
