import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { userState } from "../../../interface/MainInterface";
import LoadingCircle from "../../../utils/LoadingCircle";
import Toast from "../../../utils/Toast";

import { HttpAdminApi } from "../../../interface/admin-api";

interface propsType {
  userState: userState;
  channelInfo: any;
  callback: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "400px",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "4px",
  boxShadow: 24,
  pl: 2,
  pr: 2,
  pb: 3,
  pt: 1,
};

const adminApi = new HttpAdminApi();

const ChangeBroadTitle = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const newTitleRef: any = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [newTitle, setNewTitle] = useState("");

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setOpenModal(false);
      setNewTitle("");
    };
  }, []);

  const open = () => {
    console.log("props.channelInfo.broad_title : ", props.channelInfo.broad_title);
    setNewTitle(props.channelInfo.broad_title);
    setOpenModal(true);
  };

  const update_broad_title = async () => {
    if (newTitle === "" || newTitle.trim() === "") {
      toastRef.current?.toast("방송명을 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center" });
      newTitleRef.current.focus();
      return;
    }

    if (!window.confirm("방송명을 변경하시겠습니까?")) return;

    setIsLoading(true);
    const param: any = {
      command: "update_broad_title",
      channel_pk: props.channelInfo.channel_pk,
      broad_seq: props.channelInfo.broad_seq,
      broad_title: newTitle,
    };
    const res = await adminApi.post(param);
    if (res.code === "200") {
      props.callback({ command: "finish_change_title" });
      setOpenModal(false);
    } else {
      toastRef.current?.toast(res.response.error_msg + "", "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="column" spacing={0}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <Tooltip title="창닫기">
                <IconButton
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  <CloseIcon className="color-red" />
                </IconButton>
              </Tooltip>
            </Box>
            <Stack direction="column" spacing={2}>
              <TextField
                inputRef={newTitleRef}
                value={newTitle}
                placeholder="새로운 방송명을 입력하세요."
                variant="outlined"
                fullWidth
                required
                type="search"
                inputProps={{ enterKeyHint: "Enter", style: { padding: "12px 16px 10px 16px" } }}
                onChange={(e) => {
                  setNewTitle(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") update_broad_title();
                }}
              />
              <Button variant="contained" fullWidth onClick={update_broad_title}>
                방송명 변경
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default forwardRef(ChangeBroadTitle);
