import { useEffect, useState, useCallback, useRef } from "react";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
import awsExports from "./config/aws-exports";
import "./App.css";

import * as AuthMain from "./auth/AuthMain";
import { userState } from "./interface/MainInterface";
import { useWindowSize } from "./utils/useWindowSize";
import { CommonUtils } from "./utils/common_utils";
import Header from "./mainContainer/Header";
import MainConteiner from "./mainContainer/MainConteiner";

import "@fontsource/noto-sans-kr";

Amplify.configure(awsExports);

let userParam: userState = {
  id: "",
  name: "",
  company: "",
  isAuth: false,
  isPermission: false,
  isAdmin: false,
  isSuperAdmin: false,
  apiUrl: "",
  picture: "",
};

const largeDrawerWidth: number = 250;
const smallDrawerWidth: number = 0;
const zeroDrawerWidth: number = 0;

const LayoutDivider = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  minHeight: "44px !important",
  padding: theme.spacing(0, 0),
  justifyContent: "flex-end",
}));

const theme = createTheme({
  typography: {
    fontFamily: "Pretendard, sans-serif",
  },
  palette: {
    // primary: {
    //   main: "#527FED",
    //   contrastText: "#fff",
    // },
    info: {
      main: "#009000",
      contrastText: "#fff",
    },
    error: {
      main: "#ff0000",
      contrastText: "#fff",
    },
    warning: {
      main: "#ffd300",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9ea0a6",
      contrastText: "#fff",
    },
  },
  zIndex: { drawer: 990, modal: 990 },
});

function App() {
  const utils = new CommonUtils();
  const windowSize = useWindowSize();

  const headerRef: any = useRef();
  const [userState, setUserState] = useState<userState>();
  const [open, setOpen] = useState(true);
  const [winSize, setWinSize] = useState("lg");
  const [selectedMenu, setSelectedMenu] = useState("");
  const [headerLarge, setHeaderLarge] = useState(true);
  const [drawerWidth, setDrawerWidth] = useState(zeroDrawerWidth);
  const [contentMargin, setContentMargin] = useState(zeroDrawerWidth);
  const [searchResult, setSearchResult] = useState<any>([]);

  async function setUserInfo() {
    // 세션체크
    const result = await AuthMain.checkAuthenticated();
    if (result) {
      userParam.isAuth = true;
      // 사용자 정보 조회
      const userAttr = await AuthMain.getUserAttr();
      userParam.id = userAttr.email;
      userParam.name = userAttr.name;
      userParam.picture = userAttr.picture;

      // 어드민 여부 체크
      const adminInfo = await AuthMain.checkAdmin();
      userParam.company = adminInfo.company;
      userParam.isPermission = adminInfo.isPermission;
      userParam.isAdmin = adminInfo.isAdmin;
      userParam.isSuperAdmin = adminInfo.isSuperAdmin;
    }
    setUserState(userParam);
  }

  useEffect(() => {
    setUserInfo();
  }, []);

  const handleDrawer = useCallback(() => {
    setHeaderLarge(!headerLarge);

    if (winSize === "xs") {
      setContentMargin((contentMargin) => zeroDrawerWidth);
      if (!headerLarge) {
        setDrawerWidth((drawerWidth) => largeDrawerWidth);
      } else {
        setDrawerWidth((drawerWidth) => zeroDrawerWidth);
      }
    } else {
      if (!headerLarge) {
        setDrawerWidth((drawerWidth) => largeDrawerWidth);
        setContentMargin((contentMargin) => largeDrawerWidth);
      } else {
        setDrawerWidth((drawerWidth) => smallDrawerWidth);
        setContentMargin((contentMargin) => smallDrawerWidth);
      }
    }
  }, [headerLarge, winSize]);

  const menuClose = useCallback(() => {
    setDrawerWidth((drawerWidth) => smallDrawerWidth);
    setContentMargin((contentMargin) => smallDrawerWidth);
  }, []);

  const menuOver = () => {
    if (winSize === "xs" || winSize === "md" || winSize === "sm") {
      setHeaderLarge(true);
      setDrawerWidth((drawerWidth) => largeDrawerWidth);
    } else setDrawerWidth((drawerWidth) => largeDrawerWidth);
  };

  const menuOut = () => {
    if (winSize === "xs" || winSize === "md" || winSize === "sm") {
      setHeaderLarge(false);
      setDrawerWidth((drawerWidth) => zeroDrawerWidth);
    } else setDrawerWidth((drawerWidth) => smallDrawerWidth);
  };

  useEffect(() => {
    const size = utils.calculateWindowSize(windowSize.width);
    if (size === "xs" || size === "md" || size === "sm" || selectedMenu === "/live" || selectedMenu === "/statistic") {
      setHeaderLarge(false);
      setContentMargin((contentMargin) => zeroDrawerWidth);
      setDrawerWidth((drawerWidth) => zeroDrawerWidth);
    } else {
      setHeaderLarge(true);
      setContentMargin((contentMargin) => largeDrawerWidth);
      setDrawerWidth((drawerWidth) => largeDrawerWidth);
    }
    setWinSize(size);
  }, [windowSize, selectedMenu]);

  const getLeftCount = () => {
    headerRef.current.getLeftCount();
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {userState !== undefined && (
          <BrowserRouter>
            {selectedMenu === "/" ||
            selectedMenu === "/signin" ||
            selectedMenu === "/signup" ||
            selectedMenu === "/broadcastGuide" ||
            selectedMenu === "/broadcastDownload" ? (
              <></>
            ) : userState.isAuth ? (
              <>
                <Header
                  ref={headerRef}
                  userState={userState}
                  open={open}
                  winSize={winSize}
                  selectedMenu={selectedMenu}
                  headerLarge={headerLarge}
                  drawerWidth={drawerWidth}
                  largeDrawerWidth={largeDrawerWidth}
                  handleDrawer={handleDrawer}
                  menuOver={menuOver}
                  menuOut={menuOut}
                />
                <LayoutDivider />
              </>
            ) : (
              <></>
            )}

            <MainConteiner
              userState={userState}
              drawerWidth={
                selectedMenu === "/" ||
                selectedMenu === "/signin" ||
                selectedMenu === "/signup" ||
                selectedMenu === "/broadcastGuide" ||
                selectedMenu === "/broadcastDownload"
                  ? 0
                  : contentMargin
              }
              setSelectedMenu={setSelectedMenu}
              winSize={winSize}
              getLeftCount={getLeftCount}
              searchResult={searchResult}
              setSearchResult={setSearchResult}
            />
          </BrowserRouter>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
