import { useState, forwardRef, useImperativeHandle } from "react";
import { useWindowSize } from "../../../utils/useWindowSize";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import LoadingCircle from "../../../utils/LoadingCircle";
import { HttpAdminApi } from "../../../interface/admin-api";
import { HttpAdminIotApi } from "../../../interface/admin-iot-rest-api";

interface propsType {
  broadSeq: string;
  callBack: any;
}

const adminApi = new HttpAdminApi();
const adminIotApi = new HttpAdminIotApi();

const ChatBlock = (props: propsType, ref: any) => {
  const windowSize = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [blockTime, setBlockTime] = useState("5");
  const [blockUserArn, setBlockUserArn] = useState("");
  const [blockUserName, setBlockUserName] = useState("");
  const handleBlockTimeChange = (event: SelectChangeEvent) => {
    setBlockTime(event.target.value as string);
  };

  const open = (message: any) => {
    setBlockUserArn(message.Sender.Arn);
    setBlockUserName(message.Sender.Name);
    setOpenDialog(true);
  };

  const fncNo = () => {
    setOpenDialog(false);
  };
  const fncYes = async () => {
    if (window.confirm(blockUserName + "님의 채팅을 금지하시겠습니까?")) {
      setIsLoading(true);
      const param: any = {
        command: "add_block_user",
        broad_seq: props.broadSeq,
        block_user_arn: blockUserArn,
        block_time: blockTime,
      };
      const result = await adminApi.post(param);

      if (result.code === "200" && result.response.result === "success") {
        const param: any = {
          topic: `live/${props.broadSeq}/payload`,
          payload: {
            topic: "chat/block",
            blockUserArn: blockUserArn,
            blockTime: result.response.block_time_stamp,
          },
        };
        await adminIotApi.send_iot_message(param);
        alert("채팅 금지를 완료했습니다.");
        setOpenDialog(false);
      } else {
        alert("오류가 발생했습니다. IT담당자에게 문의하세요.");
      }
      setIsLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <Dialog
      open={openDialog}
      onClose={() => {
        fncNo();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"채팅금지"}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ width: windowSize.width > 768 ? "300px" : "200px", mt: 1 }} direction="column">
          <Box sx={{ minWidth: windowSize.width > 768 ? 120 : "50px" }}>
            <FormControl fullWidth size="small">
              <InputLabel id="search-use-yn-select-label">시간</InputLabel>
              <Select
                labelId="search-use-yn-select-label"
                id="search-use-yn-select-label"
                value={blockTime}
                label="시간"
                onChange={handleBlockTimeChange}
              >
                <MenuItem value={"5"}>5분</MenuItem>
                <MenuItem value={"10"}>10분</MenuItem>
                <MenuItem value={"3000"}>영구</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            fncNo();
          }}
        >
          취소
        </Button>
        <Button
          onClick={() => {
            fncYes();
          }}
        >
          적용
        </Button>
      </DialogActions>
      <LoadingCircle loading={isLoading} />
    </Dialog>
  );
};

export default forwardRef(ChatBlock);
