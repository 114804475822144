import Box from "@mui/material/Box";

const BroadcastGuide = () => {
  return (
    <>
      <Box sx={{ width: "100%", height: "100vh" }}>
        <iframe src="/guide/guide.html" width="100%" height="100%" style={{ border: 0 }} />
      </Box>
    </>
  );
};

export default BroadcastGuide;
