import { useEffect, useRef, useState } from "react";
import { Storage } from "aws-amplify";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { CardMedia, Input } from "@mui/material";

import { userState } from "../../interface/MainInterface";
import { HttpAdminApi } from "../../interface/admin-api";

interface propsType {
  userState: userState;
  callback: any;
}

const adminApi = new HttpAdminApi();

const ChangePicture = (props: propsType) => {
  const myPhotoFileRef = useRef<HTMLInputElement>();
  const [userPictureUrl, setUserPictureUrl] = useState<string>("");
  const [hover, setHover] = useState(false);

  useEffect(() => {
    return () => {
      setUserPictureUrl("");
    };
  }, []);

  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      const file = e.target.files[0];
      const newFileName = "user_picture";

      try {
        const photoKey = props.userState.id + "/" + newFileName;
        const data = await Storage.put(photoKey, file, { customPrefix: { public: "user-photos/" } });
        getUrl(data.key);
      } catch (e) {
        console.error("fail uploadding : ", e);
        getUrl("");
      }
    }
  };

  const update_user_picture = async () => {
    myPhotoFileRef.current?.click();
  };

  useEffect(() => {
    if (props.userState.picture !== "") {
      getUrl(props.userState.picture);
    }
  }, [props]);

  const getUrl = async (path: string) => {
    if (props.userState.picture !== "") {
      const param: any = {
        command: "get_presigned_url",
        key: "user-photos/" + path,
      };
      const res = await adminApi.post(param);
      if (res.code === "200") {
        setUserPictureUrl(res.response.url);
      }
    }
  };

  return (
    <>
      <Box
        sx={{ position: "relative" }}
        onMouseOver={() => {
          setHover(true);
        }}
        onMouseOut={() => {
          setHover(false);
        }}
      >
        {userPictureUrl !== "" && <CardMedia component={"img"} image={userPictureUrl} sx={{ height: "200px", borderRadius: "50%" }} />}
        <Box sx={{ position: "absolute", bottom: "20px", left: "50%", transform: "translateX(-50%)", display: hover ? "" : "none" }}>
          <Button variant="contained" size="small" onClick={update_user_picture}>
            변경
          </Button>
        </Box>
      </Box>
      <Input
        color="primary"
        type="file"
        inputRef={myPhotoFileRef}
        onChange={handleFileInput}
        sx={{ display: "none" }}
        inputProps={{ accept: "image/*" }}
      />
    </>
  );
};

export default ChangePicture;
