import { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Pie } from "react-chartjs-2";
import { CommonUtils } from "../common_utils";

interface propsType {
  dataRows: any;
  chartTitle: any;
  labelTitles: any;
  backgroundColors: any;
  legend: any;
}

interface IGridCols {
  label: string;
  value: number;
}

Chart.register(CategoryScale);

const cUtils = new CommonUtils();

const PieChart = (props: propsType) => {
  const [chartData, setChartData] = useState<any>({});

  useEffect(() => {
    if (props.dataRows.length > 0) {
      let newDataRows = [...props.dataRows];
      const labels: any = newDataRows[0].map((data: IGridCols) => data.label);
      const datasets: any = [];
      // eslint-disable-next-line array-callback-return
      newDataRows.map((data: any, index: number) => {
        const temp: any = {
          label: props.labelTitles[index],
          data: data.map((col: IGridCols) => col.value),
          backgroundColor: props.backgroundColors,
        };
        datasets.push(temp);
      });
      let data: any = {
        labels: labels,
        datasets: datasets,
      };

      // console.log(data);
      setChartData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataRows]);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setChartData({});
    };
  }, []);

  return (
    <div className="chart-root">
      {!cUtils.isEmptyObj(chartData) && (
        <Pie
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: props.chartTitle.display,
                text: props.chartTitle.text,
              },
              legend: {
                display: props.legend.display,
                position: props.legend.position,
              },
            },
            interaction: {
              intersect: false,
            },
          }}
        />
      )}
    </div>
  );
};

export default PieChart;
