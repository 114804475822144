import { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

import { Box, Stack, Divider, Typography, Button, Pagination, Tooltip } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  DataGrid,
  GridColDef,
  useGridApiRef,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
  GridRowSelectionModel,
  GridPaginationModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import { HttpChannelApi } from "../../interface/channel-api";
import { HttpAdminApi } from "../../interface/admin-api";

import { userState, broadStatusName } from "../../interface/MainInterface";
import { CommonUtils } from "../../utils/common_utils";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import { useWindowSize } from "../../utils/useWindowSize";

interface propsType {
  userState: userState;
}

const channelApi = new HttpChannelApi();
const adminApi = new HttpAdminApi();
const cUtils = new CommonUtils();

const pageSize: number = 100;

const TotalStatistic = (props: propsType) => {
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  const toastRef: any = useRef();
  const gridApi = useGridApiRef();
  const [isLoading, setIsLoading] = useState(false);

  const [srchDateFrom, setSrchDateFrom] = useState<Dayjs | null>(dayjs().startOf("month"));
  const [srchDateTo, setSrchDateTo] = useState<Dayjs | null>(dayjs().endOf("month"));

  const [channelList, setChannelList] = useState<any>([]);

  const [gridHeight, setGridHeight] = useState(608);
  const [selectedRow, setSelectedRow] = useState<GridRowSelectionModel>([]);
  const [selectedPage, setSelectedPage] = useState<GridPaginationModel>({ pageSize: pageSize, page: 0 });

  const columns: GridColDef[] = [
    {
      field: "broad_start_tm",
      headerName: "시작일시",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{param.row.broad_start_tm !== "" ? dayjs(param.row.broad_start_tm).format("MM-DD HH:mm") : ""}</span>
          </>
        );
      },
    },
    {
      field: "host_id",
      headerName: "관리자",
      width: 220,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "broad_status",
      headerName: "상태",
      width: 80,
      headerAlign: "center",
      align: "center",
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{broadStatusName[param.row.broad_status]}</span>
          </>
        );
      },
    },
    {
      field: "broad_title",
      headerName: "방송명",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    { field: "total_views", headerName: "누적시청자", width: 140, headerAlign: "right", align: "right" },
    { field: "live_total_prod_click", headerName: "상품클릭", width: 140, headerAlign: "right", align: "right" },
    { field: "total_likes", headerName: "좋아요", width: 140, headerAlign: "right", align: "right" },
    {
      field: "live_total_delivered_secs",
      headerName: "시청시간",
      width: 140,
      headerAlign: "right",
      align: "right",
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{convertTotalViewTime(param.row.live_total_delivered_secs)}</span>
          </>
        );
      },
    },
    {
      field: "chat_list",
      headerName: "채팅 다운로드",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.row.broad_seq === "total_sum" ? (
              <></>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  getListChannelMessages(params.row.broad_seq, params.row.broad_start_tm, params.row.broad_title);
                }}
              >
                다운로드
              </Button>
            )}
          </>
        );
      },
    },
    {
      field: "pk",
      headerName: "세부통계",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            {param.row.broad_seq === "total_sum" ? (
              <></>
            ) : (
              <Tooltip title="방송중 또는 방송종료 후 확인 가능">
                <Box>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate(`/statistic/${param.row.broad_seq}`);
                    }}
                    disabled={param.row.broad_status === "CREATE" || param.row.broad_status === "READY"}
                  >
                    자세히
                  </Button>
                </Box>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    get_channel_statistics_list();
    return () => {
      setIsLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_channel_statistics_list = useCallback(async () => {
    if (props.userState.id === "" || props.userState.id === undefined) return;
    setIsLoading(true);

    const param = {
      command: "get_channel_statistics_list",
      search_condition: {
        broad_date: [dayjs(srchDateFrom).format("YYYYMMDD"), dayjs(srchDateTo).add(1, "day").format("YYYYMMDD")],
        broad_status: ["CREATE", "START", "READY", "STOP", "VOD"],
        sort: "DESCEND",
      },
      request_user_id: props.userState.id,
    };

    const res = await channelApi.post(param);
    if (res.result_code === "200") {
      let tempList: any = [];
      let total_sum_like_cnt = 0;
      let total_sum_view_cnt = 0;
      let total_sum_prod_click_cnt = 0;
      let total_sum_delivered_secs = 0;
      for (const body of res.result_body) {
        total_sum_like_cnt = total_sum_like_cnt + body.total_likes;
        total_sum_view_cnt = total_sum_view_cnt + body.total_views;
        total_sum_prod_click_cnt = total_sum_prod_click_cnt + body.live_total_prod_click;
        total_sum_delivered_secs = total_sum_delivered_secs + body.live_total_delivered_secs;

        body.total_likes = cUtils.numericComma(Number(body.total_likes));
        body.total_views = cUtils.numericComma(Number(body.total_views));
        body.unique_total_view_cnt = cUtils.numericComma(Number(body.unique_total_view_cnt));
        body.live_total_prod_click = cUtils.numericComma(Number(body.live_total_prod_click));
        tempList.push(body);
      }

      tempList.unshift({
        broad_seq: "total_sum",
        broad_start_tm: "",
        host_id: "총계",
        total_likes: cUtils.numericComma(Number(total_sum_like_cnt)),
        total_views: cUtils.numericComma(Number(total_sum_view_cnt)),
        live_total_prod_click: cUtils.numericComma(Number(total_sum_prod_click_cnt)),
        live_total_delivered_secs: total_sum_delivered_secs,
      });
      setChannelList(tempList);
    } else {
      console.error(res.result_body);
    }

    setIsLoading(false);
  }, [srchDateFrom, srchDateTo, props.userState.id]);

  // 최초 채널 입장시 기존 차임 메시지 조회(현재 최대 50건)
  const getListChannelMessages = async (broad_seq: string, broad_start_tm: String, broad_title: String) => {
    const param: any = {
      command: "list_chat_history",
      broad_seq: broad_seq,
      next_token: undefined,
      list_mode: "total",
    };
    setIsLoading(true);
    await adminApi.post(param).then((result: any) => {
      if (result.code === "200") {
        // console.log("result : ", result);
        // sheet header
        let chatHistory: any = [["순번", "채팅일시", "작성자", "내용"]];
        // 각 행 순번 만들기
        let chatIndex = 1;
        // 내용 생성
        for (const message of result.response.chat_history.Messages) {
          const detail = [
            chatIndex,
            dayjs(String(message.CreatedTimestamp)).format("YYYY-MM-DD HH:mm:ss"),
            `${message.Sender.Name}(${
              message.Sender.Arn.split("/user/").length > 1 ? message.Sender.Arn.split("/user/")[1] : message.Sender.Arn
            })`,
            message.Content,
          ];
          chatHistory = [...chatHistory, detail];
          chatIndex += 1;
        }
        // 컬럼 넓이
        const colWidth = [{ wpx: 40 }, { wpx: 150 }, { wpx: 230 }, { wpx: 800 }];
        const sheetName = "채팅내역";
        // 파일명
        const fileName = dayjs(String(broad_start_tm)).format("YYYY-MM-DD HHmm") + "_채팅_" + broad_title + ".xlsx";
        cUtils.downloadExcel(chatHistory, colWidth, sheetName, fileName);
      }
    });
    setIsLoading(false);
  };

  const downloadExcelList = () => {
    if (channelList.length === 0) {
      toastRef.current?.toast("다운로드할 데이터가 없습니다.", "error", 3000);
      return;
    }
    // 컬럼 넓이
    const colWidth = [{ wpx: 40 }, { wpx: 150 }, { wpx: 200 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }];
    // sheet header
    let statisticsHistory: any = [["순번", "시작일시", "관리자", "방송명", "좋아요", "누적시청자", "상품클릭", "시청시간(초)"]];
    // 각 행 순번 만들기
    let statisticsIndex = 1;
    // 내용 생성
    for (const item of channelList) {
      let datetime = "";
      if (item.broad_start_tm !== "") {
        datetime = dayjs(item.broad_start_tm, "YYYYMMDDHHmmss").format("MM-DD HH:mm");
      }
      const detail = [
        statisticsIndex,
        datetime,
        item.host_id,
        item.broad_title,
        item.total_likes,
        item.total_views,
        item.live_total_prod_click,
        cUtils.numericComma(Number(item.live_total_delivered_secs)),
      ];
      statisticsHistory = [...statisticsHistory, detail];
      statisticsIndex += 1;
    }

    const statsticsSheetName = "통계내역";
    // 파일명
    const fileName = `[${dayjs(srchDateFrom).format("YYYY-MM-DD")}~${dayjs(srchDateTo).format("YYYY-MM-DD")}] 방송별통계.xlsx`;
    cUtils.downloadExcel(statisticsHistory, colWidth, statsticsSheetName, fileName);
  };

  const convertTotalViewTime = (totalSec: number) => {
    const t = cUtils.convertSecToTime(totalSec);
    return `${t.hours}:${t.minutes}:${t.seconds}`;
  };

  function CustomPagination() {
    const page = useGridSelector(gridApi, gridPageSelector);
    const pageCount = useGridSelector(gridApi, gridPageCountSelector);
    return (
      <Box className="tenants-list-pagenation">
        <Pagination
          color="primary"
          shape="rounded"
          count={pageCount === 0 ? 1 : pageCount}
          page={page + 1}
          siblingCount={10}
          onChange={(e, v) => gridApi.current.setPage(v - 1)}
        ></Pagination>
      </Box>
    );
  }

  useEffect(() => {
    setGridHeight(windowSize.height - 220);
  }, [windowSize]);

  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Stack direction={"column"} spacing={0}>
          <Box className="content-header-root">
            <Typography variant="h5" className="content-header-title">
              방송별 통계
            </Typography>
          </Box>
          <Divider />
          <Box className="content-content-root">
            <Stack direction={"column"} spacing={1}>
              <Box className="content-content-header" sx={{ pb: 0 }}>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker", "DatePicker"]}>
                        <DatePicker
                          label="방송시작 From"
                          showDaysOutsideCurrentMonth
                          format="YYYY-MM-DD"
                          value={srchDateFrom}
                          onChange={(newValue) => setSrchDateFrom(newValue)}
                          sx={{ width: "160px", minWidth: "160px !important" }}
                          slotProps={{
                            textField: { size: "small" },
                          }}
                        />
                        <DatePicker
                          label="방송시작 To"
                          showDaysOutsideCurrentMonth
                          format="YYYY-MM-DD"
                          value={srchDateTo}
                          onChange={(newValue) => setSrchDateTo(newValue)}
                          sx={{ width: "160px", minWidth: "160px !important" }}
                          slotProps={{
                            textField: { size: "small" },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </Stack>
                <Box sx={{ pt: "10px", minWidth: "200px", display: "flex", flexDirection: "row", gap: "16px", justifyContent: "flex-end" }}>
                  <Button variant="contained" color="primary" onClick={get_channel_statistics_list}>
                    조회
                  </Button>
                  <Button variant="contained" size="small" onClick={downloadExcelList}>
                    EXCEL
                  </Button>
                </Box>
              </Box>
              {/* 라이브 리스트 */}
              <Box sx={{ width: "100%", height: gridHeight }}>
                <DataGrid
                  apiRef={gridApi}
                  rows={channelList}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: pageSize,
                      },
                    },
                    sorting: {
                      sortModel: [
                        {
                          field: "range_num",
                          sort: "asc",
                        },
                      ],
                    },
                  }}
                  slots={{ pagination: CustomPagination }}
                  hideFooterSelectedRowCount
                  getRowId={(row) => row.broad_seq}
                  rowSelectionModel={selectedRow}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRow(newRowSelectionModel);
                  }}
                  paginationModel={selectedPage}
                  onPaginationModelChange={(newPagenationModel) => {
                    setSelectedPage(newPagenationModel);
                  }}
                  rowHeight={(gridHeight - 60 - 60 + 11) / 10}
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default TotalStatistic;
