import { useEffect, useState, useRef } from "react";
import { Box, Stack, Button, ButtonGroup, Typography } from "@mui/material";
import { useWindowSize } from "../../../utils/useWindowSize";

import { HttpChannelApi } from "../../../interface/channel-api";
import { CommonUtils } from "../../../utils/common_utils";

import { userState } from "../../../interface/MainInterface";

import { useInterval } from "../../../utils/UseInterval";
import dayjs from "dayjs";

import Toast from "../../../utils/Toast";

interface propsType {
  userState: userState;
  channelInfo: any;
  callback: any;
}

const channelApi = new HttpChannelApi();
const cUtils = new CommonUtils();

const LiveStatistic = (props: propsType) => {
  const windowSize = useWindowSize();
  const toastRef: any = useRef();

  const [statisticsList, setStatisticsList] = useState<any>([]);

  const [concurentView, setConcurentView] = useState(0);
  const [totalLike, setTotalLike] = useState(0);
  const [totalLiveLike, setTotalLiveLike] = useState(0);
  const [totalView, setTotalView] = useState(0);
  const [totalLiveView, setTotalLiveView] = useState(0);
  const [totalViewTime, setTotalViewTime] = useState(0);
  const [prodClickList, setProdClickList] = useState<any>([]);
  const [broadTime, setBroadTime] = useState("00:00:00");

  useEffect(() => {
    return () => {
      setConcurentView(0);
      setTotalLike(0);
      setTotalLiveLike(0);
      setTotalView(0);
      setTotalLiveView(0);
      setTotalViewTime(0);
      setProdClickList(0);
      setBroadTime("00:00:00");
    };
  }, []);

  useEffect(() => {
    if (!cUtils.isEmptyObj(props.channelInfo)) get_statistics();
  }, [props.channelInfo]);

  const get_statistics = () => {
    get_statistics_data();
    get_prod_click();
  };

  // 통계 데이터 조회
  const get_statistics_data = async () => {
    const param = {
      command: "get_statistics_data",
      pk: props.channelInfo.channel_pk,
      broad_seq: props.channelInfo.broad_seq,
      broad_status: props.channelInfo.broad_status,
    };

    const res = await channelApi.post(param);
    // console.log("statistic_data : ", res);
    if (res.result_code === "200") {
      setStatisticsList(res.result_body.statistic);
      setTotalLike(res.result_body.total_likes);
      setTotalView(res.result_body.total_views);
    }
  };

  const get_prod_click = async () => {
    const param: any = {
      command: "get_prod_click",
      broad_seq: props.channelInfo.broad_seq,
    };

    await channelApi.post(param).then((result: any) => {
      if (result.result_code === "200") {
        setProdClickList(result.result_body);
      } else {
        setProdClickList([]);
      }
    });
  };

  useEffect(() => {
    if (statisticsList.length > 0) {
      let newDataRows = [...statisticsList];

      const totalList = [];
      let lastConcurentView: any = undefined;
      let totalLiveViewTimeSec = 0;
      let totalLiveEnterCnt = 0;
      let totalLiveLikeCnt = 0;

      for (const data of newDataRows) {
        const time = dayjs(data.sk).format("YYYY-MM-DD HH:mm");
        totalList.push({
          time: time,
          concurrent: data.concurrent_cnt,
          enter: data.enter_cnt,
          like: data.like_cnt,
          diliveredTime: data.deliverd_sec,
        });
        lastConcurentView = data.concurrent_cnt;
        totalLiveViewTimeSec = totalLiveViewTimeSec + data.deliverd_sec;
        totalLiveEnterCnt = totalLiveEnterCnt + data.enter_cnt;
        totalLiveLikeCnt = totalLiveLikeCnt + data.like_cnt;
      }
      if (lastConcurentView !== undefined) {
        setConcurentView(lastConcurentView);
      }
      setTotalViewTime(totalLiveViewTimeSec);
      setTotalLiveView(totalLiveEnterCnt);
      setTotalLiveLike(totalLiveLikeCnt);
    }
  }, [statisticsList]);

  const delay: number = 60000; // 1분마다 변경
  useInterval(
    () => {
      get_statistics();
    },
    props.channelInfo.broad_status === "START" ? delay : null
  );

  const time: number = 1000;
  useInterval(
    () => {
      setBroadTime(convertTotalViewTime(cUtils.calPassedSec(props.channelInfo.broad_start_tm)));
    },
    props.channelInfo.broad_status === "START" ? time : null
  );

  const convertTotalViewTime = (totalSec: number) => {
    const t = cUtils.convertSecToTime(totalSec);
    return `${t.hours}:${t.minutes}:${t.seconds}`;
  };

  return (
    <>
      <Box>
        <Stack direction={"row"} spacing={2}>
          <ButtonGroup variant="outlined" fullWidth>
            {windowSize.width > 768 && (
              <Button>
                <Typography sx={{ fontSize: "20px", fontWeight: "700", color: "#000000" }}>라이브 보드</Typography>
              </Button>
            )}
            <Button
              onClick={() => {
                // setViewChart(1);
              }}
            >
              <Stack direction={"column"} spacing={0}>
                <Typography sx={{ fontSize: windowSize.width > 768 ? "20px" : "14px", fontWeight: "700", color: "#000000" }}>
                  {windowSize.width > 768 ? `${cUtils.numericComma(totalLiveView)}명` : `${cUtils.numericComma(totalLiveView)}`}
                </Typography>
                <Typography sx={{ fontSize: windowSize.width > 768 ? "12px" : "10px", fontWeight: "400", color: "#00000090" }}>
                  {windowSize.width > 768
                    ? `누적 시청자수 (VOD:${cUtils.numericComma(totalView - totalLiveView)}명)`
                    : `누적(V:${cUtils.numericComma(totalView - totalLiveView)})`}
                </Typography>
              </Stack>
            </Button>
            <Button
              onClick={() => {
                // setViewChart(0);
              }}
            >
              <Stack direction={"column"} spacing={0}>
                <Typography sx={{ fontSize: windowSize.width > 768 ? "20px" : "14px", fontWeight: "700", color: "#000000" }}>
                  {windowSize.width > 768 ? `${cUtils.numericComma(concurentView)}명` : `${cUtils.numericComma(concurentView)}`}
                </Typography>
                <Typography sx={{ fontSize: windowSize.width > 768 ? "12px" : "10px", fontWeight: "400", color: "#00000090" }}>
                  {windowSize.width > 768 ? "동시 시청자수" : "동시"}
                </Typography>
              </Stack>
            </Button>
            <Button
              onClick={() => {
                // setViewChart(1);
              }}
            >
              <Stack direction={"column"} spacing={0}>
                <Typography sx={{ fontSize: windowSize.width > 768 ? "20px" : "14px", fontWeight: "700", color: "#000000" }}>
                  {windowSize.width > 768 ? `${cUtils.numericComma(totalLiveLike)}건` : `${cUtils.numericComma(totalLiveLike)}`}
                </Typography>
                <Typography sx={{ fontSize: windowSize.width > 768 ? "12px" : "10px", fontWeight: "400", color: "#00000090" }}>
                  {windowSize.width > 768
                    ? `좋아요수 (VOD:${cUtils.numericComma(totalLike - totalLiveLike)}명)`
                    : `좋아요(V:${cUtils.numericComma(totalLike - totalLiveLike)})`}
                </Typography>
              </Stack>
            </Button>
            <Button
              onClick={() => {
                // setViewChart(3);
              }}
            >
              <Stack direction={"column"} spacing={0}>
                <Typography sx={{ fontSize: windowSize.width > 768 ? "20px" : "14px", fontWeight: "700", color: "#000000" }}>
                  {windowSize.width > 768
                    ? `${cUtils.numericComma(prodClickList.length)}회`
                    : `${cUtils.numericComma(prodClickList.length)}`}
                </Typography>
                <Typography sx={{ fontSize: windowSize.width > 768 ? "12px" : "10px", fontWeight: "400", color: "#00000090" }}>
                  {windowSize.width > 768 ? "상품 클릭수" : "상품"}
                </Typography>
              </Stack>
            </Button>
            {props.channelInfo.broad_status === "START" && windowSize.width > 768 && (
              <Button
                onClick={() => {
                  // setViewChart(3);
                }}
              >
                <Stack direction={"column"} spacing={0}>
                  <Typography sx={{ fontSize: "20px", fontWeight: "700", color: "#000000" }}>{broadTime}</Typography>
                  <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#00000090" }}>라이브 진행시간</Typography>
                </Stack>
              </Button>
            )}
          </ButtonGroup>
        </Stack>
      </Box>
      <Toast ref={toastRef} />
    </>
  );
};

export default LiveStatistic;
