import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import { Box, Stack, Divider, Typography, Tooltip } from "@mui/material";

import { HttpAdminApi } from "../../interface/admin-api";

import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import StatisticInfo from "./Statistic/StatisticInfo";

interface propsType {
  userState: userState;
}

const adminApi = new HttpAdminApi();

const ChannelStatistic = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const { broadSeq } = useParams();

  const [channelInfo, setChannelInfo] = useState<any>({});

  useEffect(() => {
    get_channel_info();
    return () => {
      setIsLoading(false);
      setChannelInfo({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_channel_info = async () => {
    const param: any = {
      command: "get_channel_info",
      broad_seq: broadSeq,
    };

    const res = await adminApi.post(param);
    if (res.code === "200") {
      const info: any = res.response.channel_info;
      info.channel_pk = `broad_date#${dayjs(info.broad_start_tm).format("YYYYMMDD")}`;
      setChannelInfo(res.response.channel_info);
    } else {
      console.error("getChannelInfo 에러 발생 : " + res.response.error_msg);
    }
  };

  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Stack direction={"column"} spacing={0}>
          <Box className="content-header-root">
            <Tooltip title={dayjs(channelInfo.broad_start_tm).format("YYYY-MM-DD HH:mm")}>
              <Typography variant="h5" className="content-header-title">
                {`방송 통계 : ${channelInfo?.broad_title ? channelInfo?.broad_title : ""}`}
              </Typography>
            </Tooltip>
          </Box>
          <Divider />
          <Box className="content-content-root">
            <Stack direction={"column"} spacing={2}>
              {/* 방송통계영역 */}
              <StatisticInfo userState={props.userState} channelInfo={channelInfo} callback={() => {}} />
            </Stack>
          </Box>
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default ChannelStatistic;
