import { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

import { Box, Stack, Divider, Typography, Button, Pagination, Tooltip, Chip } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CheckIcon from "@mui/icons-material/Check";

import {
  DataGrid,
  useGridApiRef,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
  GridRowSelectionModel,
  GridPaginationModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import { HttpAdminApi } from "../../interface/admin-api";

import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

interface propsType {
  userState: userState;
}

const adminApi = new HttpAdminApi();

const pageSize: number = 100;

const ErrorMonitor = (props: propsType) => {
  const toastRef: any = useRef();
  const gridApi = useGridApiRef();

  const [isLoading, setIsLoading] = useState(false);

  const [srchDateFrom, setSrchDateFrom] = useState<Dayjs | null>(dayjs().subtract(90, "day"));
  const [srchDateTo, setSrchDateTo] = useState<Dayjs | null>(dayjs().add(7, "day"));

  const [errorList, setErrorList] = useState<any[]>([]);

  const gridHeight = 608;
  const [selectedRow, setSelectedRow] = useState<GridRowSelectionModel>([]);
  const [selectedPage, setSelectedPage] = useState<GridPaginationModel>({ pageSize: pageSize, page: 0 });

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setErrorList([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: any[] = [
    {
      field: "sk",
      headerName: "발생일시",
      width: 130,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{param.row.broad_start_tm !== "" ? dayjs(param.row.sk, "YYYYMMDDHHmmssFF").format("MM-DD HH:mm:ss") : ""}</span>
          </>
        );
      },
    },
    { field: "broad_seq", headerName: "방송순번", width: 320, editable: true },
    {
      field: "device_info-1",
      headerName: "브라우저",
      width: 70,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{param.row.device_info.isBrowser && <CheckIcon />}</span>
          </>
        );
      },
    },
    {
      field: "device_info-2",
      headerName: "모바일",
      width: 70,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{param.row.device_info.isMobile && <CheckIcon />}</span>
          </>
        );
      },
    },
    {
      field: "device_info-3",
      headerName: "OS",
      width: 140,
      editable: true,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{`${param.row.device_info.osName}(${param.row.device_info.osVersion})`}</span>
          </>
        );
      },
    },
    {
      field: "device_info-4",
      headerName: "Browser",
      width: 140,
      editable: true,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{`${param.row.device_info.browserName}(${param.row.device_info.fullBrowserVersion})`}</span>
          </>
        );
      },
    },
    {
      field: "device_info-5",
      headerName: "Phone",
      width: 140,
      editable: true,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{param.row.device_info.mobileModel}</span>
          </>
        );
      },
    },
    { field: "error_msg", headerName: "에러", flex: 1, minWidth: 300, editable: true },
  ];

  const fncGetErrorList = async () => {
    if (props.userState.id === "" || props.userState.id === undefined) return;
    setIsLoading(true);

    const param: any = {
      command: "get_error_list",
      std_dates: [dayjs(srchDateFrom).format("YYYYMMDD"), dayjs(srchDateTo).add(1, "day").format("YYYYMMDD")],
    };

    const res = await adminApi.post(param);
    if (res.code === "200") {
      console.log("res.response.error_list : ", res.response.error_list);
      setErrorList(res.response.error_list);
    } else {
      toastRef.current?.toast(res.response.error_msg, "error", 5000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  };

  function CustomPagination() {
    const page = useGridSelector(gridApi, gridPageSelector);
    const pageCount = useGridSelector(gridApi, gridPageCountSelector);
    return (
      <Box className="tenants-list-pagenation">
        <Pagination
          color="primary"
          shape="rounded"
          count={pageCount === 0 ? 1 : pageCount}
          page={page + 1}
          siblingCount={10}
          onChange={(e, v) => gridApi.current.setPage(v - 1)}
        ></Pagination>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Stack direction={"column"} spacing={0}>
          <Box className="content-header-root">
            <Typography variant="h5" className="content-header-title">
              오류 모니터
            </Typography>
          </Box>
          <Divider />
          <Box className="content-content-root">
            <Stack direction={"column"} spacing={1}>
              <Box className="content-content-header" sx={{ pb: 0 }}>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker", "DatePicker"]}>
                        <DatePicker
                          label="기준일 From"
                          showDaysOutsideCurrentMonth
                          format="YYYY-MM-DD"
                          value={srchDateFrom}
                          onChange={(newValue) => setSrchDateFrom(newValue)}
                          sx={{ width: "160px", minWidth: "160px !important" }}
                          slotProps={{
                            textField: { size: "small" },
                          }}
                        />
                        <DatePicker
                          label="기준일 To"
                          showDaysOutsideCurrentMonth
                          format="YYYY-MM-DD"
                          value={srchDateTo}
                          onChange={(newValue) => setSrchDateTo(newValue)}
                          sx={{ width: "160px", minWidth: "160px !important" }}
                          slotProps={{
                            textField: { size: "small" },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </Stack>
                <Box sx={{ pt: "10px", minWidth: "200px", display: "flex", flexDirection: "row", gap: "16px", justifyContent: "flex-end" }}>
                  <Button variant="contained" color="primary" onClick={fncGetErrorList}>
                    조회
                  </Button>
                </Box>
              </Box>
              {/* 오류 목록 */}
              <Box sx={{ width: "100%", height: gridHeight }}>
                <DataGrid
                  apiRef={gridApi}
                  rows={errorList}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: pageSize,
                      },
                    },
                    sorting: {
                      sortModel: [
                        {
                          field: "range_num",
                          sort: "asc",
                        },
                      ],
                    },
                  }}
                  slots={{ pagination: CustomPagination }}
                  hideFooterSelectedRowCount
                  getRowId={(row) => row.sk}
                  rowSelectionModel={selectedRow}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRow(newRowSelectionModel);
                  }}
                  paginationModel={selectedPage}
                  onPaginationModelChange={(newPagenationModel) => {
                    setSelectedPage(newPagenationModel);
                  }}
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default ErrorMonitor;
