import Backdrop from "@mui/material/Backdrop";
import Stack from "@mui/material/Stack";

interface propsType {
  loading: boolean;
}

const LoadingCircle = (props: propsType) => {
  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2, mt: "0px !important" }} open={props.loading}>
        <Stack direction="column" sx={{ mt: "0px !important" }}>
          <div className="load-cont">
            <div className="spinner-wrap">
              <div className="spinner">
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
          </div>
        </Stack>
      </Backdrop>
    </>
  );
};

export default LoadingCircle;
