import { ApiBase } from "./api-base";

export class HttpAdminApi extends ApiBase {
  onApiName(): string {
    return "AdminApi";
  }

  onUrlPath(): string {
    return "";
  }

  public async post(param: any) {
    const response = await this.requestBuffer(param);
    return response;
  }
}
