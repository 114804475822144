import { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { useWindowSize } from "../../utils/useWindowSize";

import { Box, Stack, Divider, Typography, Button, Pagination, Tooltip, Chip, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  DataGrid,
  GridCellParams,
  GridColDef,
  useGridApiRef,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
  GridRowSelectionModel,
  GridPaginationModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import { HttpAdminApi } from "../../interface/admin-api";

import { userState, broadStatusName } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import CreateChannel from "./ChannelManage/CreateChannel";

interface propsType {
  userState: userState;
}

const adminApi = new HttpAdminApi();

const LiveList = (props: propsType) => {
  const windowSize = useWindowSize();
  const toastRef: any = useRef();
  const gridApi = useGridApiRef();
  const navigate = useNavigate();
  const createChannelRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [srchDateFrom, setSrchDateFrom] = useState<Dayjs | null>(dayjs().subtract(90, "day"));
  const [srchDateTo, setSrchDateTo] = useState<Dayjs | null>(dayjs().add(7, "day"));
  const [srchBroadTitle, setSrchBroadTitle] = useState("");

  const [channelList, setChannelList] = useState<any>([]);

  const gridHeight = 608;
  const [selectedRow, setSelectedRow] = useState<GridRowSelectionModel>([]);
  let pageSize: number = 10;
  if (windowSize.width < 768) {
    pageSize = 5;
  }
  const [selectedPage, setSelectedPage] = useState<GridPaginationModel>({ pageSize: pageSize, page: 0 });

  const columns: GridColDef[] = [
    {
      field: "broad_title",
      headerName: "방송명",
      flex: 1,
      minWidth: 400,
      headerAlign: "center",
      align: "left",
      renderCell: (param: GridRenderCellParams) => {
        return (
          <Box
            onClick={() => {
              navigate(`/live/${param.row.broad_seq}`);
            }}
          >
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2 }}
              sx={windowSize.width > 768 ? { display: "flex", alignItems: "center" } : { display: "flex", justifyContent: "center" }}
            >
              {windowSize.width > 768 ? (
                <>
                  <Box sx={{ width: "80px" }}>{dayjs(param.row.broad_start_tm).format("MM-DD HH:mm")}</Box>
                  <Chip
                    label={broadStatusName[param.row.broad_status]}
                    color={
                      param.row.broad_status === "START"
                        ? "error"
                        : param.row.broad_status === "VOD"
                        ? "primary"
                        : param.row.broad_status === "STOP"
                        ? "warning"
                        : "secondary"
                    }
                    sx={{ width: "55px", fontSize: "13px" }}
                  />
                </>
              ) : (
                <Stack direction={"row"} spacing={2} sx={{ alignItems: "center" }}>
                  <Chip
                    label={broadStatusName[param.row.broad_status]}
                    color={
                      param.row.broad_status === "START"
                        ? "error"
                        : param.row.broad_status === "VOD"
                        ? "primary"
                        : param.row.broad_status === "STOP"
                        ? "warning"
                        : "secondary"
                    }
                    sx={{ width: "55px", fontSize: "13px" }}
                  />
                  <Box sx={{ width: "80px" }}>{dayjs(param.row.broad_start_tm).format("MM-DD HH:mm")}</Box>
                </Stack>
              )}

              <Box>
                <Typography sx={{ fontSize: "14px" }}>{param.row.broad_title}</Typography>
              </Box>
            </Stack>
          </Box>
        );
      },
    },
    {
      field: "host_id",
      headerName: "관리자",
      width: 220,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "pk",
      headerName: "",
      width: 220,
      headerAlign: "center",
      align: "right",
      renderCell: (param: GridRenderCellParams) => {
        return (
          <Stack direction={"row"} spacing={2}>
            <Tooltip title="라이브 방송 진행 관리">
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(`/live/${param.row.broad_seq}`);
                  }}
                >
                  라이브
                </Button>
              </Box>
            </Tooltip>

            <Tooltip title="방송중 또는 방송종료 후 확인 가능">
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(`/statistic/${param.row.broad_seq}`);
                  }}
                  disabled={param.row.broad_status === "CREATE" || param.row.broad_status === "READY"}
                >
                  통계
                </Button>
              </Box>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    getChannelList();
    return () => {
      setIsLoading(false);
      setSrchBroadTitle("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChannelList = async () => {
    if (props.userState.id === "" || props.userState.id === undefined) return;

    const param: any = {
      command: "get_channel_list",
      search_condition: {
        broad_date: [dayjs(srchDateFrom).format("YYYYMMDD"), dayjs(srchDateTo).add(1, "day").format("YYYYMMDD")],
        broad_status: ["CREATE", "START", "READY", "STOP", "VOD"],
        sort: "DESCEND",
      },
      request_user_id: props.userState.id,
    };
    setIsLoading(true);
    const res = await adminApi.post(param);
    if (res.code === "200") {
      // 방송명 검색
      if (srchBroadTitle !== "") {
        let temp: any = [];
        for (const channelInfo of res.response.channel_list) {
          const title = channelInfo.broad_title;
          if (title.indexOf(srchBroadTitle) > -1) {
            temp = [...temp, channelInfo];
          }
        }
        setChannelList(temp);
      } else {
        setChannelList(res.response.channel_list);
      }
    } else {
      console.error(res.response.error_msg);
    }
    setIsLoading(false);
  };

  function CustomPagination() {
    const page = useGridSelector(gridApi, gridPageSelector);
    const pageCount = useGridSelector(gridApi, gridPageCountSelector);
    return (
      <Box className="tenants-list-pagenation">
        <Pagination
          color="primary"
          shape="rounded"
          count={pageCount === 0 ? 1 : pageCount}
          page={page + 1}
          siblingCount={windowSize.width > 768 ? 10 : 0}
          onChange={(e, v) => gridApi.current.setPage(v - 1)}
        ></Pagination>
      </Box>
    );
  }

  const evtGridDblClick = useCallback(
    (param: GridCellParams) => {
      navigate(`/live/${param.row.broad_seq}`);
    },
    [navigate]
  );

  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Stack direction={"column"} spacing={0}>
          <Box className="content-header-root">
            <Typography variant="h5" className="content-header-title">
              라이브 목록
            </Typography>
          </Box>
          <Divider />

          <Box className="content-content-root">
            <Stack direction={"column"} spacing={1}>
              {windowSize.width > 768 ? (
                //PC버전 검색조건
                <Box className="content-content-header" sx={{ pb: 0 }}>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker", "DatePicker"]}>
                          <DatePicker
                            label="방송시작 From"
                            showDaysOutsideCurrentMonth
                            format="YYYY-MM-DD"
                            value={srchDateFrom}
                            onChange={(newValue) => setSrchDateFrom(newValue)}
                            sx={{ width: "160px", minWidth: "160px !important" }}
                            slotProps={{
                              textField: { size: "small" },
                            }}
                          />
                          <DatePicker
                            label="방송시작 To"
                            showDaysOutsideCurrentMonth
                            format="YYYY-MM-DD"
                            value={srchDateTo}
                            onChange={(newValue) => setSrchDateTo(newValue)}
                            sx={{ width: "160px", minWidth: "160px !important" }}
                            slotProps={{
                              textField: { size: "small" },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                    <Box sx={{ pt: 1, width: "400px" }}>
                      <TextField
                        id="txtTile"
                        label="방송명"
                        variant="outlined"
                        size="small"
                        value={srchBroadTitle}
                        type="search"
                        fullWidth
                        inputProps={{ enterKeyHint: "Enter" }}
                        onChange={(e) => {
                          setSrchBroadTitle(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            getChannelList();
                          }
                        }}
                      />
                    </Box>
                  </Stack>
                  <Box
                    sx={{ pt: "10px", minWidth: "200px", display: "flex", flexDirection: "row", gap: "16px", justifyContent: "flex-end" }}
                  >
                    {props.userState.isSuperAdmin && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          createChannelRef.current.open();
                        }}
                      >
                        생성
                      </Button>
                    )}
                    <Button variant="contained" color="primary" onClick={getChannelList}>
                      조회
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Stack direction={"column"} spacing={1}>
                  <Stack direction={"row"} spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="방송시작 From"
                        showDaysOutsideCurrentMonth
                        format="YYYY-MM-DD"
                        value={srchDateFrom}
                        onChange={(newValue) => setSrchDateFrom(newValue)}
                        slotProps={{
                          textField: { size: "small" },
                        }}
                      />
                      <DatePicker
                        label="방송시작 To"
                        showDaysOutsideCurrentMonth
                        format="YYYY-MM-DD"
                        value={srchDateTo}
                        onChange={(newValue) => setSrchDateTo(newValue)}
                        slotProps={{
                          textField: { size: "small" },
                        }}
                      />
                    </LocalizationProvider>
                  </Stack>
                  <Box sx={{ width: "100%" }}>
                    <TextField
                      id="txtTile"
                      label="방송명"
                      variant="outlined"
                      size="small"
                      value={srchBroadTitle}
                      type="search"
                      fullWidth
                      inputProps={{ enterKeyHint: "Enter" }}
                      onChange={(e) => {
                        setSrchBroadTitle(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          getChannelList();
                        }
                      }}
                    />
                  </Box>
                  <Button variant="contained" color="primary" onClick={getChannelList}>
                    조회
                  </Button>
                </Stack>
              )}

              {/* 라이브 리스트 */}
              <Box sx={{ width: "100%", height: gridHeight }}>
                <DataGrid
                  apiRef={gridApi}
                  rows={channelList}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: pageSize,
                      },
                    },
                    sorting: {
                      sortModel: [
                        {
                          field: "range_num",
                          sort: "asc",
                        },
                      ],
                    },
                  }}
                  slots={{ pagination: CustomPagination }}
                  hideFooterSelectedRowCount
                  getRowId={(row) => row.sk}
                  rowSelectionModel={selectedRow}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRow(newRowSelectionModel);
                  }}
                  paginationModel={selectedPage}
                  onPaginationModelChange={(newPagenationModel) => {
                    setSelectedPage(newPagenationModel);
                  }}
                  onCellDoubleClick={evtGridDblClick}
                  rowHeight={windowSize.width > 768 ? (gridHeight - 60 - 60 + 11) / 10 : (gridHeight - 60 - 60 + 11) / 5}
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
      <CreateChannel
        ref={createChannelRef}
        userState={props.userState}
        callback={(payload: any) => {
          if (payload.command === "finish_crete_channel") {
            toastRef.current?.toast("채널을 생성했습니다.", "success", 4000, {
              vertical: "top",
              horizontal: "center",
            });
            getChannelList();
          }
        }}
      />
    </>
  );
};

export default LiveList;
