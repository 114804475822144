import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { saveAs } from "file-saver";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import DownloadIcon from "@mui/icons-material/Download";
import CircularProgress from "@mui/material/CircularProgress";

import { userState, appConfig } from "../../../interface/MainInterface";
import { CommonUtils } from "../../../utils/common_utils";
import { useInterval } from "../../../utils/UseInterval";

import { HttpChannelApi } from "../../../interface/channel-api";

interface propsType {
  userState: userState;
  recordInfo: any;
  broadInfo: any;
}
const channelApi = new HttpChannelApi();
const VodMediaList = (props: propsType) => {
  const cUtils = new CommonUtils();
  const [mediaInfo, setMediaInfo] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [jobWorking, setJobWorking] = useState(false);

  const getMediaInfo = async () => {
    setLoading(true);
    const param = {
      command: "get_media_info",
      record_info: props.recordInfo,
    };

    const res = await channelApi.post(param);
    if (res.result_code === "200") {
      setMediaInfo(res.result_body);
    } else {
      console.error("[ERROR] getMediaInfo : ", res.result_body);
    }
    setLoading(false);
  };

  const getMediaInfoNoLoading = async () => {
    const param = {
      command: "get_media_info",
      record_info: props.recordInfo,
    };

    const res = await channelApi.post(param);
    if (res.result_code === "200") {
      setMediaInfo(res.result_body);
    } else {
      console.error("[ERROR] getMediaInfo : ", res.result_body);
    }
  };

  useEffect(() => {
    if (!cUtils.isEmptyObj(mediaInfo)) {
      if (!mediaInfo.exist_clip || !mediaInfo.exist_full) {
        if (mediaInfo.job_id_clip !== "" || mediaInfo.job_id_full !== "") {
          setJobWorking(true);
        } else {
          setJobWorking(false);
        }
      } else {
        setJobWorking(false);
      }
    } else {
      setJobWorking(false);
    }
  }, [mediaInfo]);

  const downloadMedia = async (record_started_at: string, record_started_text: string, type: string) => {
    const fileUrl =
      appConfig.vod_cloudfront_endpoint + "/vod/" + props.broadInfo.broad_seq + "/" + record_started_at + "/playlist-" + type + ".mp4";
    // console.log("fileUrl : ", fileUrl);
    setLoading(true);
    await fetch(fileUrl, { method: "GET" })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        setLoading(false);
        saveAs(blob, `${record_started_text}-${props.broadInfo.broad_title}-${type}.mp4`);
      })
      .catch((err) => {
        setLoading(false);
        console.error("err : ", err);
      });
    setLoading(false);
  };

  const makeDownloadMedia = async (record_root: string, started_at: string) => {
    // 권한
    if (!props.userState.isSuperAdmin && props.userState.id !== props.broadInfo.host_id) {
      alert("다른 사람의 채널을 수정하실 수 없습니다.");
      return;
    }
    if (!window.confirm("다운로드용 영상을 생성하시겠습니까?")) return;
    setLoading(true);
    const param: any = {
      command: "make_download_media",
      broad_info: {
        pk: props.broadInfo.pk,
        broad_seq: props.broadInfo.broad_seq,
        broad_title: props.broadInfo.broad_title,
        broad_desc: props.broadInfo.broad_desc,
        broad_start_tm: props.broadInfo.broad_start_tm,
        broad_stop_tm: props.broadInfo.broad_stop_tm,
        broad_prod_list: props.broadInfo.broad_prod_list,
        broad_notice: props.broadInfo.broad_notice,
        broad_status: "VOD",
        vod_url: "/" + record_root + "/media/hls/master.m3u8",
        vod_start_sec: props.broadInfo.vod_start_sec,
        vod_end_sec: props.broadInfo.vod_end_sec,
        high_start_sec: props.broadInfo.high_start_sec,
        vod_started_at: started_at,
        password: props.broadInfo.password,
        extra_type: props.broadInfo.extra_type,
        progress_store: props.broadInfo.progress_store,
        host_id: props.broadInfo.host_id,
        channel_img: props.broadInfo.channel_img,
        channel_template: props.broadInfo.channel_template,
      },
    };

    const res = await channelApi.post(param);
    console.log("res:", res);
    setLoading(false);
    if (res.result_code === "200") {
      alert(
        "다운로드용 영상 생성을 요청하였습니다. 영상의 길이에 따라서 생성시간이 길어질 수 있습니다.\n영상 생성이 완료되면 다운로드 버튼이 활성화 됩니다."
      );
      getMediaInfo();
    } else {
      alert(`[ERROR] ${res.result_body}`);
    }
  };

  // for useInterval
  const delay: number = 5000; // 1초마다 변경
  useInterval(
    () => {
      getMediaInfoNoLoading();
    },
    jobWorking ? delay : null
  );

  useEffect(() => {
    // 녹화된 영상 상태 조회
    getMediaInfo();
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setMediaInfo({});
      setLoading(false);
      setJobWorking(false);
    };
  }, []);

  return (
    <Box>
      {loading ? (
        <Button variant="outlined">
          <CircularProgress size={24} />
        </Button>
      ) : (
        <Box>
          <Stack sx={{ width: "100%" }} direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, sm: 2, md: 2 }}>
            {mediaInfo.exist_clip ? (
              <Button
                variant="outlined"
                onClick={() => {
                  downloadMedia(
                    dayjs(new Date(props.recordInfo.stream_info.recording_started_at)).format("YYYYMMDDHHmmss").toString(),
                    dayjs(new Date(props.recordInfo.stream_info.recording_started_at)).format("YYYY-MM-DD HH:mm").toString(),
                    "clip"
                  );
                }}
              >
                CLIP
                <DownloadIcon />
              </Button>
            ) : mediaInfo.job_id_clip !== "" ? (
              <Button variant="outlined" onClick={() => {}}>
                <CircularProgress size={12} />
                생성중({mediaInfo.job_clip_percent}%)
              </Button>
            ) : (
              <></>
            )}
            {mediaInfo.exist_full ? (
              <Button
                variant="outlined"
                onClick={() => {
                  downloadMedia(
                    dayjs(new Date(props.recordInfo.stream_info.recording_started_at)).format("YYYYMMDDHHmmss").toString(),
                    dayjs(new Date(props.recordInfo.stream_info.recording_started_at)).format("YYYY-MM-DD HH:mm").toString(),
                    "full"
                  );
                }}
              >
                FULL
                <DownloadIcon />
              </Button>
            ) : mediaInfo.job_id_full !== "" ? (
              <Button variant="outlined" onClick={() => {}}>
                <CircularProgress size={12} />
                생성중({mediaInfo.job_full_percent}%)
              </Button>
            ) : (
              <></>
            )}
            {!mediaInfo.exist_clip && !mediaInfo.exist_full && mediaInfo.job_id_clip === "" && mediaInfo.job_id_full === "" ? (
              <Button
                variant="outlined"
                onClick={() => {
                  makeDownloadMedia(
                    props.recordInfo.stream_info.bucket_root,
                    dayjs(new Date(props.recordInfo.stream_info.recording_started_at)).format("YYYYMMDDHHmmss").toString()
                  );
                }}
              >
                다운로드 영상 생성
              </Button>
            ) : (
              <></>
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default VodMediaList;
