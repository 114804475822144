import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import "./broadcastDownload.css";

interface propsType {}

const BroadcastDownload = (props: propsType) => {
  return (
    <>
      <Box sx={{ width: "100%", height: "100vh", background: "#4867a5", flexGrow: 1, display: "flex", alignItems: "center", px: 5 }}>
        <Container maxWidth="sm" className="broadcast-download-container">
          <Typography
            className="broadcast-download-title"
            sx={{ fontSize: "1.7rem", fontWeight: "700" }}
            align="center"
            color="text.primary"
            gutterBottom
          >
            어디서든 언제든 빠르게, 더현대 라이브
          </Typography>
          <Typography className="broadcast-download-subtitle" align="center" color="text.secondary" sx={{ fontSize: "1.3rem", mb: 0 }}>
            지금 바로 초저지연 송출앱을 경험하세요.
          </Typography>
          <Stack sx={{ pt: 4 }} direction="row" spacing={2} justifyContent="center">
            <a
              target="_blank"
              className="fixed-download-button"
              id="header-download-button-android"
              href="https://liveappdownload.s3.ap-northeast-2.amazonaws.com/apk/app-release.apk"
            >
              <img className="fixed-google-play" alt="android app" src="/images/icon_play store_.svg" />
              <div className="fixed-download-text">Android</div>
            </a>
            <a
              target="_blank"
              className="fixed-download-button"
              id="header-download-button-ios"
              href="itms-services://?action=download-manifest&url=https://liveappdownload.s3.ap-northeast-2.amazonaws.com/manifest.plist"
            >
              <img className="fixed-ios" alt="ios app" src="/images/icon_Apple_.svg" />
              <div className="fixed-download-text">iOS</div>
            </a>
          </Stack>
        </Container>
        {/* </Grid> */}
      </Box>
    </>
  );
};

export default BroadcastDownload;
