import { useEffect, useRef, forwardRef, useImperativeHandle } from "react";

declare global {
  interface Window {
    IVSPlayer: any;
  }
}

export let player: any = null;

interface propsType {}

const VideoPreviewModule = (props: propsType, ref: any) => {
  const videoRef: any = useRef();
  // HLS 세팅
  const mediaPlayerScriptLoaded = () => {
    const MediaPlayerPackage = window.IVSPlayer;
    if (!MediaPlayerPackage.isPlayerSupported) {
      console.warn("지원하지 않는 브라우저입니다. 다른 브라우저로 시도하세요.");
    }

    // Initialize player
    player = MediaPlayerPackage.create({
      serviceWorker: {
        url: "amazon-ivs-service-worker-loader.js",
      },
    });
    player.attachHTMLVideoElement(document.getElementById("videoPlayer"));

    player.setAutoQualityMode(true);
    player.setMuted(true);
    player.setVolume(1.0);
  };

  useEffect(() => {
    if (videoRef.current) mediaPlayerScriptLoaded();
  }, [videoRef.current]);

  const play = (playback: string) => {
    player.load(playback);
    player.play();
  };

  useImperativeHandle(ref, () => ({
    play,
  }));

  return (
    <video
      ref={videoRef}
      className="client-video"
      id={"videoPlayer"}
      poster="/images/ready_img.png"
      preload="auto"
      playsInline
      muted
      controls
      autoPlay
    ></video>
  );
};

export default forwardRef(VideoPreviewModule);
