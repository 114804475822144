import { ApiBase } from "./api-base";

export class HttpAdminIotApi extends ApiBase {
  onApiName(): string {
    return "AdminIotApiName";
  }

  onUrlPath(): string {
    return "/iot";
  }

  public async send_iot_message(param: any) {
    let requestBody: any = param;
    requestBody.command = "send_iot_message";
    const response = this.requestPost(requestBody);
    return response;
  }
}
