import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { Storage } from "aws-amplify";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import { Input, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import * as AuthMain from "../../auth/AuthMain";
import { HttpAdminApi } from "../../interface/admin-api";
import { HttpMainApi } from "../../interface/main-api";

interface propsType {
  userState: userState;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "4px",
  boxShadow: 24,
  pl: 2,
  pr: 2,
  pb: 3,
  pt: 1,
};
const forbiddenWords = ["관리자", "사회자", "어드민", "슈퍼 어드민", "Admin"];

const adminApi = new HttpAdminApi();
const mainApi = new HttpMainApi();

const ChangeName = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const newNameRef: any = useRef();
  const myPhotoFileRef = useRef<HTMLInputElement>();

  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [newName, setNewName] = useState("");
  const [userPicture, setUserPicture] = useState<string>("");
  const [userPictureUrl, setUserPictureUrl] = useState<string>("");

  useEffect(() => {
    console.log("userState : ", props.userState);
    return () => {
      setIsLoading(false);
      setOpenModal(false);
      setNewName("");
      setUserPicture("");
      setUserPictureUrl("");
    };
  }, []);

  const open = useCallback((open: boolean) => {
    setNewName("");
    setOpenModal(open);
  }, []);

  const fncChangeName = async () => {
    if (newName === "" || newName.trim() === "") {
      toastRef.current?.toast("이름을 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center" });
      newNameRef.current.focus();
      return;
    }

    if (!props.userState.isAdmin && forbiddenWords.includes(newName)) {
      toastRef.current?.toast("사용할 수 없는 이름입니다.", "error", 4000, { vertical: "top", horizontal: "center" });
      newNameRef.current.focus();
      return;
    }

    setIsLoading(true);
    // cognito 닉네임 변경
    let result = await AuthMain.setChatName(newName);
    console.log("result : ", result);

    // 사용자테이블 이름 업데이트
    const param: any = {
      command: "update_user_name",
      user_id: props.userState.id,
      user_name: newName,
    };
    const res = await mainApi.post(param);
    console.log("res : ", res);
    setIsLoading(false);
  };

  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const acceptableFormats = ["image/jpeg", "image/jpg", "image/png", "image/bmp", "image/gif"];
    if (e.target.files !== null) {
      const file = e.target.files[0];
      if (!acceptableFormats.includes(file.type)) {
        alert("이미지 파일만 Upload 가능합니다.");
        return;
      }

      const newFileName = "user_picture";

      try {
        const photoKey = props.userState.id + "/" + newFileName;
        const data = await Storage.put(photoKey, file, { customPrefix: { public: "user-photos/" } });
        setUserPicture(data.key);
        setUserAvatar(data.key);
      } catch (e) {
        console.error("fail uploadding : ", e);
        setUserPicture("");
        setUserAvatar("");
      }
    }
  };

  const setUserAvatar = async (userPicture: string) => {
    const param: any = {
      command: "get_presigned_url",
      key: `user-photos/${userPicture}`,
    };
    const res = await adminApi.post(param);
    console.log("res : ", res);

    // const image_url = await clientApi.get_presigned_url("user-photos/" + userPicture);
    // setUserPictureUrl(image_url.result_body.url);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="column" spacing={0}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <Tooltip title="창닫기">
                <IconButton
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  <CloseIcon className="color-red" />
                </IconButton>
              </Tooltip>
            </Box>
            <Stack direction="column" spacing={2}>
              <TextField
                inputRef={newNameRef}
                value={newName}
                placeholder="Enter your new name"
                variant="outlined"
                fullWidth
                required
                type="search"
                inputProps={{ enterKeyHint: "Enter", style: { padding: "12px 16px 10px 16px" } }}
                onChange={(e) => {
                  setNewName(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") fncChangeName();
                }}
              />
              <Button variant="contained" fullWidth onClick={fncChangeName}>
                Change name
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
      {/* <Input color="primary" type="file" inputRef={myPhotoFileRef} onChange={handleFileInput} sx={{ display: "none" }} /> */}
    </>
  );
};

export default forwardRef(ChangeName);
