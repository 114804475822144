/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/
const awsmobile = {
  aws_project_region: "ap-northeast-2",

  Auth: {
    region: "ap-northeast-2",
    userPoolId: "ap-northeast-2_PtHcN8pj6", // Please change this value.
    userPoolWebClientId: "14fmhbqs9u8vivu12slfj43rfh", // Please change this value.
    identityPoolId: "ap-northeast-2:58141503-e6c8-45ae-95d0-bebe1c893d5f", // Please change this value.
  },
  Storage: {
    AWSS3: {
      bucket: "liveitnedev-channelbackendstack-wa-ap-northeast-2-14627",
      region: "ap-northeast-2",
    },
  },
  aws_cloud_logic_custom: [
    {
      name: "MainApi",
      endpoint: "https://s7zaitvt61.execute-api.ap-northeast-2.amazonaws.com/Dev",
      region: "ap-northeast-2",
    },
    {
      name: "UnAuth",
      endpoint: "https://s7zaitvt61.execute-api.ap-northeast-2.amazonaws.com/Dev",
      region: "ap-northeast-2",
    },
    {
      name: "ChannelApi",
      endpoint: "https://vdkl1ns3i5.execute-api.ap-northeast-2.amazonaws.com/Dev",
      region: "ap-northeast-2",
    },
    {
      name: "AdminIotApiName",
      endpoint: "https://j4t72ulbni.execute-api.ap-northeast-2.amazonaws.com/Dev",
      region: "ap-northeast-2",
    },
    {
      name: "Statistic",
      endpoint: "https://uwdnyllmbc.execute-api.ap-northeast-2.amazonaws.com/Dev",
      region: "ap-northeast-2",
    },
  ],
};

export default awsmobile;
