import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";

import { Box, Stack, Modal, Typography, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Toast from "../../../../utils/Toast";
import LoadingCircle from "../../../../utils/LoadingCircle";
import { userState } from "../../../../interface/MainInterface";
import S3UploaderViaApi from "../../../../utils/attach/S3UploaderViaApi";

interface propsType {
  userState: userState;
  callback: any;
}

const PopUploadImageViaApi = (props: propsType, ref: any) => {
  const toastRef: any = useRef();

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [attachFiles, setAttachFiles] = useState<any>([]);
  const [videoKey, setVideoKey] = useState("");

  useEffect(() => {
    return () => {
      setOpenModal(false);
      setAttachFiles([]);
      setVideoKey("");
    };
  }, []);

  const open = useCallback((_videoKey: string) => {
    if (_videoKey !== undefined) setVideoKey(_videoKey);
    setOpenModal(true);
  }, []);

  const close = () => {
    setAttachFiles([]);
    setVideoKey("");
    props.callback({ command: "close" });
    setOpenModal(false);
  };

  // 첨부파일 업로드 완료시 콜백
  const fncUploadComplete = async (result: any) => {
    console.log("result : ", result);
    if (result.status === "ok") {
      props.callback({ command: "upload_finish", key: result.fileInfo[0]?.FILE_KEY });
      close();
    } else if (result.status === "fail") {
      setIsLoading(false);
      close();
      toastRef.current?.toast(result.msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    } else {
      setIsLoading(false);
      close();
      toastRef.current?.toast("업로드에 실패하였습니다. 잠시 후 다시 이용해주세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          close();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", md: "50%", lg: "40%" },
            maxWidth: "90%",
            maxHeight: "90%",
            bgcolor: "background.paper",
            border: "0px solid #000",
            borderRadius: "1rem",
            boxShadow: 24,
            p: 3,
            zIndex: 990,
          }}
        >
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography gutterBottom sx={{ lineHeight: "40px", fontWeight: "800 !important" }}>
                {videoKey === "" ? "새 비디오 업로드" : "비디오 파일 교체"}
              </Typography>
              <Tooltip title="창닫기">
                <IconButton aria-label="close" onClick={close}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            {attachFiles.length === 0 && (
              <S3UploaderViaApi
                userState={props.userState}
                multiple={false}
                callBack={fncUploadComplete}
                setIsLoading={setIsLoading}
                borderRadius=""
                width="100%"
                variant="contained"
                buttonText="이미지 선택"
                dragDrop={true}
                uploadDir={"event-image"}
              />
            )}
          </Stack>
        </Box>
      </Modal>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default forwardRef(PopUploadImageViaApi);
