import { useEffect, useState, useRef } from "react";
import {
  Box,
  Stack,
  Button,
  ButtonGroup,
  Typography,
  Grid,
  Paper,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CardMedia,
  Tooltip,
  IconButton,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DownloadIcon from "@mui/icons-material/Download";

import { HttpChannelApi } from "../../../interface/channel-api";
import { HttpStatisticApi } from "../../../interface/statistic-api";
import { HttpAdminApi } from "../../../interface/admin-api";
import { CommonUtils } from "../../../utils/common_utils";

import { userState } from "../../../interface/MainInterface";

import { useInterval } from "../../../utils/UseInterval";

import LineChart from "../../../utils/chart/LineChart";
import PieChart from "../../../utils/chart/PieChart";
import dayjs from "dayjs";
import Toast from "../../../utils/Toast";

interface propsType {
  userState: userState;
  channelInfo: any;
  callback: any;
}

const channelApi = new HttpChannelApi();
const statisticApi = new HttpStatisticApi();
const adminApi = new HttpAdminApi();
const cUtils = new CommonUtils();

const osStatisticDefault: any = {
  android_cnt: 0,
  ios_cnt: 0,
  pc_cnt: 0,
  total_cnt: 0,
};

const StatisticInfo = (props: propsType) => {
  const toastRef: any = useRef();
  const [viewChart, setViewChart] = useState<any>("uniqueEnter");
  const [statisticsList, setStatisticsList] = useState<any>([]);

  const [concurentView, setConcurentView] = useState(0);
  const [totalLike, setTotalLike] = useState(0);
  const [totalLiveLike, setTotalLiveLike] = useState(0);
  const [totalView, setTotalView] = useState(0);
  const [totalLiveView, setTotalLiveView] = useState(0);
  const [totalUniqueCnt, setTotalUniqueCnt] = useState(0);
  const [totalLiveUniqueView, setTotalLiveUniqueView] = useState(0);
  const [totalViewTime, setTotalViewTime] = useState(0);
  const [prodClickList, setProdClickList] = useState<any>([]);
  const [totalMemberCnt, setTotalMemberCnt] = useState(0);
  const [totalNoMemberCnt, setTotalNoMemberCnt] = useState(0);

  const [concurentViewDataRows, setConcurentViewDataRows] = useState<any>([]);
  const [enterDataRows, setEnterDataRows] = useState<any>([]);
  const [memberEnterDataRows, setMemberEnterDataRows] = useState<any>([]);
  const [noMemberenterDataRows, setNoMemberEnterDataRows] = useState<any>([]);
  const [uniqueEnterDataRows, setUniqueEnterDataRows] = useState<any>([]);
  const [uniqueMemberEnterDataRows, setUniqueMemberEnterDataRows] = useState<any>([]);
  const [uniqueNoMemberEnterDataRows, setUniqueNoMemberEnterDataRows] = useState<any>([]);
  const [sumEnterDataRows, setSumEnterDataRows] = useState<any>([]);
  const [sumMemberEnterDataRows, setSumMemberEnterDataRows] = useState<any>([]);
  const [sumNoMemberenterDataRows, setSumNoMemberEnterDataRows] = useState<any>([]);
  const [likeDataRows, setLikeDataRows] = useState<any>([]);
  const [prodDataRows, setProdDataRows] = useState<any>([]);
  const [deliveredSecDataRows, setDeliveredSecDataRows] = useState<any>([]);
  const [uniqueEnterList, setUniqueEnterList] = useState<any>([]);

  const [osStatisticInfo, setOsStatisticInfo] = useState<any>(osStatisticDefault);

  const [prodStatistics, setProdStatistics] = useState<any>([]);
  const [osTotalCnt, setOsTotalCnt] = useState(0);

  useEffect(() => {
    return () => {
      setViewChart("uniqueEnter");
      setConcurentView(0);
      setTotalLike(0);
      setTotalLiveLike(0);
      setTotalLiveUniqueView(0);
      setTotalView(0);
      setTotalUniqueCnt(0);
      setTotalLiveView(0);
      setTotalViewTime(0);
      setProdClickList([]);
      setConcurentViewDataRows([]);
      setEnterDataRows([]);
      setMemberEnterDataRows([]);
      setNoMemberEnterDataRows([]);
      setUniqueEnterDataRows([]);
      setUniqueMemberEnterDataRows([]);
      setUniqueNoMemberEnterDataRows([]);
      setLikeDataRows([]);
      setProdDataRows([]);
      setDeliveredSecDataRows([]);
      setUniqueEnterList([]);
      setOsStatisticInfo(osStatisticDefault);
      setProdStatistics([]);
    };
  }, []);

  useEffect(() => {
    if (!cUtils.isEmptyObj(props.channelInfo)) get_statistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.channelInfo]);

  const get_statistics = () => {
    get_statistics_data();
    get_prod_click();
    get_unique_view();
    get_os_statistic_info();
  };

  // 통계 데이터 조회
  const get_statistics_data = async () => {
    const param = {
      command: "get_statistics_data",
      pk: props.channelInfo.channel_pk,
      broad_seq: props.channelInfo.broad_seq,
      broad_status: props.channelInfo.broad_status,
    };

    const res = await channelApi.post(param);
    if (res.result_code === "200") {
      setStatisticsList(res.result_body.statistic);
      setTotalLike(res.result_body.total_likes);
      setTotalView(res.result_body.total_views);
      setTotalUniqueCnt(res.result_body.unique_total_cnt);
    }
  };

  const get_prod_click = async () => {
    const param: any = {
      command: "get_prod_click",
      broad_seq: props.channelInfo.broad_seq,
    };

    await channelApi.post(param).then((result: any) => {
      if (result.result_code === "200") {
        setProdClickList(result.result_body);
      } else {
        setProdClickList([]);
      }
    });
  };

  const get_unique_view = async () => {
    const param: any = {
      command: "get_unique_view",
      broad_seq: props.channelInfo.broad_seq,
    };

    await channelApi.post(param).then((result: any) => {
      if (result.code === "200") {
        setTotalMemberCnt(result.response.unique_view.member_count);
        setTotalNoMemberCnt(result.response.unique_view.nomember_count);
      }
    });
  };

  const get_os_statistic_info = async () => {
    const param: any = {
      command: "get_os_statistic_info",
      broad_seq: props.channelInfo.broad_seq,
    };

    const res = await statisticApi.post(param);
    if (res.code === "200") {
      let temp: any = res.response.os_statistic_info;
      if (temp?.ios_cnt !== undefined && temp?.android_cnt !== undefined && temp?.pc_cnt !== undefined) {
        temp.total_cnt = temp.ios_cnt + temp.android_cnt + temp.pc_cnt + temp.android_app_cnt + temp.ios_app_cnt;
        setOsStatisticInfo(temp);
      }
    }
  };

  useEffect(() => {
    if (totalView > 0 && osStatisticInfo?.total_cnt > 0) {
      setOsTotalCnt(osStatisticInfo?.total_cnt);
    }
  }, [totalView, osStatisticInfo]);

  useEffect(() => {
    if (statisticsList.length > 0) {
      let newDataRows = [...statisticsList];

      const concurentViewList = [];
      const enterCntList = [];
      const memberEnterCntList = [];
      const noMemberEnterCntList = [];
      const sumEnterCntList = [];
      const sumMemberEnterCntList = [];
      const sumNoMemberEnterCntList = [];
      const uniqueEnterCntList = [];
      const uniqueMemberEnterCntList = [];
      const uniqueNoMemberEnterCntList = [];
      const likeCntList = [];
      const prodCntList = [];
      const deliveredSecList = [];
      let totalConcurrentViewCnt = 0;
      let totalLiveViewTimeSec = 0;
      let totalLiveEnterCnt = 0;
      let totalLiveMemberEnterCnt = 0;
      let totalLiveNoMemberEnterCnt = 0;
      let totalLiveUniqueEnterCnt = 0;
      let totalLiveLikeCnt = 0;

      for (const data of newDataRows) {
        concurentViewList.push({ label: data.sk, value: data.concurrent_cnt });

        enterCntList.push({ label: data.sk, value: data.enter_cnt });
        memberEnterCntList.push({ label: data.sk, value: data.member_enter_cnt });
        noMemberEnterCntList.push({ label: data.sk, value: data.no_member_enter_cnt });

        uniqueEnterCntList.push({ label: data.sk, value: data.unique_enter_cnt });
        uniqueMemberEnterCntList.push({ label: data.sk, value: data.unique_member_enter_cnt });
        uniqueNoMemberEnterCntList.push({ label: data.sk, value: data.unique_no_member_enter_cnt });

        likeCntList.push({ label: data.sk, value: data.like_cnt });
        prodCntList.push({ label: data.sk, value: data.prod_click_cnt });
        deliveredSecList.push({ label: data.sk, value: data.deliverd_sec });

        totalConcurrentViewCnt = totalConcurrentViewCnt + data.concurrent_cnt;
        totalLiveViewTimeSec = totalLiveViewTimeSec + data.deliverd_sec;
        totalLiveEnterCnt = totalLiveEnterCnt + data.enter_cnt;
        totalLiveMemberEnterCnt = totalLiveMemberEnterCnt + data.member_enter_cnt;
        totalLiveNoMemberEnterCnt = totalLiveNoMemberEnterCnt + data.no_member_enter_cnt;
        totalLiveUniqueEnterCnt = totalLiveUniqueEnterCnt + data.unique_enter_cnt;
        totalLiveLikeCnt = totalLiveLikeCnt + data.like_cnt;

        sumEnterCntList.push({ label: data.sk, value: totalLiveEnterCnt });
        sumMemberEnterCntList.push({ label: data.sk, value: totalLiveMemberEnterCnt });
        sumNoMemberEnterCntList.push({ label: data.sk, value: totalLiveNoMemberEnterCnt });
      }

      setConcurentView(Math.round(totalConcurrentViewCnt / newDataRows.length));

      setTotalViewTime(Math.round(totalLiveViewTimeSec / totalConcurrentViewCnt));
      setTotalLiveView(totalLiveEnterCnt);
      setTotalLiveUniqueView(totalLiveUniqueEnterCnt);
      setTotalLiveLike(totalLiveLikeCnt);

      setConcurentViewDataRows(concurentViewList);
      setEnterDataRows(enterCntList);
      setMemberEnterDataRows(memberEnterCntList);
      setNoMemberEnterDataRows(noMemberEnterCntList);
      setUniqueEnterDataRows(uniqueEnterCntList);
      setUniqueMemberEnterDataRows(uniqueMemberEnterCntList);
      setUniqueNoMemberEnterDataRows(uniqueNoMemberEnterCntList);
      setSumEnterDataRows(sumEnterCntList);
      setSumMemberEnterDataRows(sumMemberEnterCntList);
      setSumNoMemberEnterDataRows(sumNoMemberEnterCntList);
      setLikeDataRows(likeCntList);
      setProdDataRows(prodCntList);
      setDeliveredSecDataRows(deliveredSecList);
    }
  }, [statisticsList]);

  const delay: number = 60000; // 1분마다 변경
  useInterval(
    () => {
      get_statistics();
    },
    props.channelInfo.broad_status === "START" ? delay : null
  );

  useEffect(() => {
    if (props.channelInfo.broad_prod_list?.length > 0) {
      let tempList: any = [];
      for (const prodInfo of props.channelInfo.broad_prod_list) {
        let tempInfo: any = {
          ...prodInfo,
        };
        let clickCnt = 0;
        let clickUsers: any[] = [];
        for (const clickInfo of prodClickList) {
          if (clickInfo.prod_id === prodInfo.goodsSn) {
            clickCnt += 1;
            if (clickUsers.indexOf(clickInfo.user_id) < 0) {
              clickUsers = [...clickUsers, clickInfo.user_id];
            }
          }
        } // end for
        tempInfo.clickCnt = clickCnt;
        tempInfo.clickUsers = clickUsers;
        tempInfo.clickRate = Math.round((clickCnt / prodClickList.length) * 100);
        tempList.push(tempInfo);
      } // end for
      setProdStatistics(tempList);
    }
  }, [props.channelInfo, prodClickList]);

  return (
    <Box>
      <Stack direction={"row"} spacing={2}>
        <ButtonGroup variant="outlined" fullWidth>
          <Button
            onClick={() => {
              setViewChart("uniqueEnter");
            }}
            sx={viewChart === "uniqueEnter" ? { backgroundColor: "#82d4ce" } : {}}
          >
            <Stack direction={"column"} spacing={0}>
              <Typography sx={{ fontSize: "20px", fontWeight: "700", color: "#000000" }}>
                {cUtils.numericComma(totalLiveUniqueView)}명
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#00000090" }}>{`순수 시청자수 (VOD:${cUtils.numericComma(
                  totalUniqueCnt - totalLiveUniqueView
                )}명)`}</Typography>
                <Tooltip title="중복을 제외한 1인 1시청수">
                  <HelpOutlineIcon sx={{ fontSize: "14px", lineHeight: "14px", ml: 0.5 }} />
                </Tooltip>
              </Box>
            </Stack>
          </Button>
          <Button
            onClick={() => {
              setViewChart("enter");
            }}
            sx={viewChart === "enter" ? { backgroundColor: "#82d4ce" } : {}}
          >
            <Stack direction={"column"} spacing={0}>
              <Typography sx={{ fontSize: "20px", fontWeight: "700", color: "#000000" }}>{cUtils.numericComma(totalLiveView)}명</Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#00000090" }}>{`시청자수 (VOD:${cUtils.numericComma(
                  totalView - totalLiveView
                )}명)`}</Typography>
                <Tooltip title="중복 구분없이 누적된 시청자의 수">
                  <HelpOutlineIcon sx={{ fontSize: "14px", lineHeight: "14px", ml: 0.5 }} />
                </Tooltip>
              </Box>
            </Stack>
          </Button>
          <Button
            onClick={() => {
              setViewChart("sumEnter");
            }}
            sx={viewChart === "sumEnter" ? { backgroundColor: "#82d4ce" } : {}}
          >
            <Stack direction={"column"} spacing={0}>
              <Typography sx={{ fontSize: "20px", fontWeight: "700", color: "#000000" }}>{cUtils.numericComma(totalLiveView)}명</Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ fontSize: "12px", lineHeight: "14px", fontWeight: "400", color: "#00000090" }}>
                  {`누적 시청자수 (VOD:${cUtils.numericComma(totalView - totalLiveView)}명)`}
                </Typography>
                <Tooltip title="중복 구분없이 누적된 시청자의 수">
                  <HelpOutlineIcon sx={{ fontSize: "14px", lineHeight: "14px", ml: 0.5 }} />
                </Tooltip>
              </Box>
            </Stack>
          </Button>
          <Button
            onClick={() => {
              setViewChart("concurrentView");
            }}
            sx={viewChart === "concurrentView" ? { backgroundColor: "#82d4ce" } : {}}
          >
            <Stack direction={"column"} spacing={0}>
              <Typography sx={{ fontSize: "20px", fontWeight: "700", color: "#000000" }}>{cUtils.numericComma(concurentView)}명</Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#00000090" }}>{`평균 동시시청자`}</Typography>
              </Box>
            </Stack>
          </Button>
          <Button
            onClick={() => {
              setViewChart("deliveredSec");
            }}
            sx={viewChart === "deliveredSec" ? { backgroundColor: "#82d4ce" } : {}}
          >
            <Stack direction={"column"} spacing={0}>
              <Typography sx={{ fontSize: "20px", fontWeight: "700", color: "#000000" }}>{cUtils.numericComma(totalViewTime)}초</Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#00000090" }}>{`평균 시청 시간`}</Typography>
            </Stack>
          </Button>
          <Button
            onClick={() => {
              setViewChart("prodClick");
            }}
            sx={viewChart === "prodClick" ? { backgroundColor: "#82d4ce" } : {}}
          >
            <Stack direction={"column"} spacing={0}>
              <Typography sx={{ fontSize: "20px", fontWeight: "700", color: "#000000" }}>
                {cUtils.numericComma(prodClickList.length)}회
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#00000090" }}>상품 클릭수</Typography>
            </Stack>
          </Button>
          <Button
            onClick={() => {
              setViewChart("like");
            }}
            sx={viewChart === "like" ? { backgroundColor: "#82d4ce" } : {}}
          >
            <Stack direction={"column"} spacing={0}>
              <Typography sx={{ fontSize: "20px", fontWeight: "700", color: "#000000" }}>{cUtils.numericComma(totalLiveLike)}회</Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#00000090" }}>{`좋야요수 (VOD:${cUtils.numericComma(
                totalLike - totalLiveLike
              )}명)`}</Typography>
            </Stack>
          </Button>
        </ButtonGroup>
      </Stack>
      <Box sx={{ mt: 2 }}></Box>
      <Box sx={{ width: "100%", height: "400px" }}>
        {viewChart === "uniqueEnter" && (
          <LineChart
            dataRows={[uniqueEnterDataRows, uniqueMemberEnterDataRows, uniqueNoMemberEnterDataRows]}
            chartTitle={{ display: true, text: "순수시청자" }}
            labelTitles={["계", "회원", "비회원"]}
            xName={"시간"}
            yName={"명"}
            lineColors={["#dc6884", "#98bde7", "#f6d1d2"]}
            backgroundColors={["#dc688480", "#98bde780", "#f6d1d280"]}
            legend={{ display: true, position: "bottom" }}
          />
        )}
        {viewChart === "enter" && (
          <LineChart
            dataRows={[enterDataRows, memberEnterDataRows, noMemberenterDataRows]}
            chartTitle={{ display: true, text: "시청자" }}
            labelTitles={["계", "회원", "비회원"]}
            xName={"시간"}
            yName={"명"}
            lineColors={["#dc6884", "#98bde7", "#f6d1d2"]}
            backgroundColors={["#dc688480", "#98bde780", "#f6d1d280"]}
            legend={{ display: true, position: "bottom" }}
          />
        )}
        {viewChart === "sumEnter" && (
          <LineChart
            dataRows={[sumEnterDataRows, sumMemberEnterDataRows, sumNoMemberenterDataRows]}
            chartTitle={{ display: true, text: "누적시청자" }}
            labelTitles={["계", "회원", "비회원"]}
            xName={"시간"}
            yName={"명"}
            lineColors={["#dc6884", "#98bde7", "#f6d1d2"]}
            backgroundColors={["#dc688480", "#98bde780", "#f6d1d280"]}
            legend={{ display: true, position: "bottom" }}
          />
        )}
        {viewChart === "concurrentView" && (
          <LineChart
            dataRows={[concurentViewDataRows]}
            chartTitle={{ display: true, text: "동시시청자" }}
            labelTitles={["동시시청자"]}
            xName={"시간"}
            yName={"명"}
            lineColors={["#dc6884", "#98bde7", "#f6d1d2"]}
            backgroundColors={["#dc688480", "#98bde780", "#f6d1d280"]}
            legend={{ display: true, position: "bottom" }}
          />
        )}
        {viewChart === "deliveredSec" && (
          <LineChart
            dataRows={[deliveredSecDataRows]}
            chartTitle={{ display: true, text: "시청시간" }}
            labelTitles={["시청시간"]}
            xName={"시간"}
            yName={"초"}
            lineColors={["#dc6884", "#98bde7", "#f6d1d2"]}
            backgroundColors={["#dc688480", "#98bde780", "#f6d1d280"]}
            legend={{ display: true, position: "bottom" }}
          />
        )}
        {viewChart === "prodClick" && (
          <LineChart
            dataRows={[prodDataRows]}
            chartTitle={{ display: true, text: "상품클릭" }}
            labelTitles={["상품클릭"]}
            xName={"시간"}
            yName={"회"}
            lineColors={["#dc6884", "#98bde7", "#f6d1d2"]}
            backgroundColors={["#dc688480", "#98bde780", "#f6d1d280"]}
            legend={{ display: true, position: "bottom" }}
          />
        )}
        {viewChart === "like" && (
          <LineChart
            dataRows={[likeDataRows]}
            chartTitle={{ display: true, text: "좋아요" }}
            labelTitles={["좋아요"]}
            xName={"시간"}
            yName={"회"}
            lineColors={["#dc6884", "#98bde7", "#f6d1d2"]}
            backgroundColors={["#dc688480", "#98bde780", "#f6d1d280"]}
            legend={{ display: true, position: "bottom" }}
          />
        )}
      </Box>
      <Box sx={{ mt: 10 }}></Box>
      <Box>
        <Typography sx={{ fontSize: "1rem", fontWeight: "700" }}>회원/비회원 & OS별 통계</Typography>
      </Box>
      <Box sx={{ mt: 4 }}></Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack direction={"row"} spacing={2} sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ height: "200px" }}>
                <PieChart
                  dataRows={[
                    [
                      { label: "회원", value: totalMemberCnt },
                      { label: "비회원", value: totalNoMemberCnt },
                    ],
                  ]}
                  chartTitle={{ display: true, text: "회원/비회원 통계" }}
                  labelTitles={["회원", "비회원"]}
                  backgroundColors={["#dc688480", "#98bde780", "#f6d1d280"]}
                  legend={{ display: true, position: "bottom" }}
                />
              </Box>
              <Box>
                <Paper sx={{ p: 3, borderRadius: "4px" }} elevation={3}>
                  <Stack direction="column" spacing={2}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography sx={{ minWidth: "100px", color: "#00000080" }}>회원</Typography>
                      <Typography
                        sx={{ minWidth: "100px", display: "flex", justifyContent: "flex-end", color: "#dc6884" }}
                      >{`${cUtils.numericComma(totalMemberCnt)}명`}</Typography>
                      <Typography sx={{ minWidth: "100px", display: "flex", justifyContent: "flex-end", color: "#dc6884" }}>{`${
                        !isNaN(totalMemberCnt / totalUniqueCnt)
                          ? cUtils.numericComma(Math.round((totalMemberCnt / totalUniqueCnt) * 1000) / 10)
                          : 0
                      }%`}</Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography sx={{ minWidth: "100px", color: "#00000080" }}>비회원</Typography>
                      <Typography
                        sx={{ minWidth: "100px", display: "flex", justifyContent: "flex-end", color: "#00000080" }}
                      >{`${cUtils.numericComma(totalNoMemberCnt)}명`}</Typography>
                      <Typography sx={{ minWidth: "100px", display: "flex", justifyContent: "flex-end", color: "#00000080" }}>{`${
                        !isNaN(totalNoMemberCnt / totalUniqueCnt)
                          ? cUtils.numericComma(Math.round((totalNoMemberCnt / totalUniqueCnt) * 1000) / 10)
                          : 0
                      }%`}</Typography>
                    </Box>
                  </Stack>
                </Paper>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction={"row"} spacing={2}>
              <Box sx={{ height: "200px" }}>
                <PieChart
                  dataRows={[
                    [
                      { label: "iOS", value: osStatisticInfo.ios_cnt + osStatisticInfo.ios_app_cnt },
                      { label: "Android", value: osStatisticInfo.android_cnt + osStatisticInfo.android_app_cnt },
                      {
                        label: "PC",
                        value:
                          osTotalCnt -
                          osStatisticInfo.android_cnt -
                          osStatisticInfo.android_app_cnt -
                          osStatisticInfo.ios_app_cnt -
                          osStatisticInfo.ios_cnt,
                      },
                    ],
                  ]}
                  chartTitle={{ display: true, text: "OS별 통계" }}
                  labelTitles={["iOS", "Android", "PC"]}
                  backgroundColors={["#dc688480", "#98bde780", "#f6d1d280"]}
                  legend={{ display: true, position: "bottom" }}
                />
              </Box>
              <Box>
                <Paper sx={{ p: 3, borderRadius: "4px" }} elevation={3}>
                  <Stack direction="column" spacing={2}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                      <Box sx={{ width: "100px" }}>
                        <Typography sx={{ color: "#00000080" }}>iOS</Typography>
                      </Box>
                      <Box sx={{ width: "100%", minWidth: "100px", display: "flex", justifyContent: "flex-end" }}>
                        <Stack direction={"column"} spacing={1} sx={{ justifyContent: "flex-end" }}>
                          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Typography>{`${cUtils.numericComma(osStatisticInfo.ios_cnt + osStatisticInfo.ios_app_cnt)}명 `}</Typography>
                          </Box>
                          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Typography sx={{ color: "#00000080" }}>{`(APP: ${cUtils.numericComma(
                              osStatisticInfo.ios_app_cnt
                            )} / WEB : ${cUtils.numericComma(osStatisticInfo.ios_cnt)})`}</Typography>
                          </Box>
                        </Stack>
                      </Box>
                      <Box sx={{ width: "100px", display: "flex", justifyContent: "flex-end" }}>
                        <Typography sx={{}}>{`${
                          isFinite((osStatisticInfo.ios_cnt + osStatisticInfo.ios_app_cnt) / osTotalCnt)
                            ? cUtils.numericComma(Math.round(((osStatisticInfo.ios_cnt + osStatisticInfo.ios_app_cnt) / osTotalCnt) * 1000) / 10)
                            : 0
                        }%`}</Typography>
                      </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                      <Box sx={{ width: "100px" }}>
                        <Typography sx={{ color: "#00000080" }}>Android</Typography>
                      </Box>
                      <Box sx={{ width: "100%", minWidth: "100px", display: "flex", justifyContent: "flex-end" }}>
                        <Stack direction={"column"} spacing={1} sx={{ justifyContent: "flex-end" }}>
                          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Typography>{`${cUtils.numericComma(
                              osStatisticInfo.android_cnt + osStatisticInfo.android_app_cnt
                            )}명 `}</Typography>
                          </Box>
                          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Typography sx={{ color: "#00000080" }}>{`(APP: ${cUtils.numericComma(
                              osStatisticInfo.android_app_cnt
                            )} / WEB : ${cUtils.numericComma(osStatisticInfo.android_cnt)})`}</Typography>
                          </Box>
                        </Stack>
                      </Box>
                      <Box sx={{ width: "100px", display: "flex", justifyContent: "flex-end" }}>
                        <Typography sx={{}}>{`${
                          isFinite((osStatisticInfo.android_cnt + osStatisticInfo.android_app_cnt) / osTotalCnt)
                            ? cUtils.numericComma(
                                Math.round(((osStatisticInfo.android_cnt + osStatisticInfo.android_app_cnt) / osTotalCnt) * 1000) / 10
                              )
                            : 0
                        }%`}</Typography>
                      </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                      <Box sx={{ width: "100px" }}>
                        <Typography sx={{ color: "#00000080" }}>PC</Typography>
                      </Box>
                      <Box sx={{ width: "100%", minWidth: "100px", display: "flex", justifyContent: "flex-end" }}>
                        <Stack direction={"column"} spacing={1} sx={{ justifyContent: "flex-end" }}>
                          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Typography>{`${cUtils.numericComma(
                              osTotalCnt -
                                osStatisticInfo.android_cnt -
                                osStatisticInfo.android_app_cnt -
                                osStatisticInfo.ios_app_cnt -
                                osStatisticInfo.ios_cnt
                            )}명 `}</Typography>
                          </Box>
                          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Typography sx={{ color: "#00000080" }}>{`(APP: 0 / WEB : ${cUtils.numericComma(
                              osTotalCnt -
                                osStatisticInfo.android_cnt -
                                osStatisticInfo.android_app_cnt -
                                osStatisticInfo.ios_app_cnt -
                                osStatisticInfo.ios_cnt
                            )})`}</Typography>
                          </Box>
                        </Stack>
                      </Box>
                      <Box sx={{ width: "120px", display: "flex", justifyContent: "flex-end" }}>
                        <Typography sx={{}}>{`${
                          isFinite(
                            (osTotalCnt -
                              osStatisticInfo.android_cnt -
                              osStatisticInfo.android_app_cnt -
                              osStatisticInfo.ios_app_cnt -
                              osStatisticInfo.ios_cnt) /
                              osTotalCnt
                          )
                            ? cUtils.numericComma(
                                Math.round(
                                  ((osTotalCnt -
                                    osStatisticInfo.android_cnt -
                                    osStatisticInfo.android_app_cnt -
                                    osStatisticInfo.ios_app_cnt -
                                    osStatisticInfo.ios_cnt) /
                                    osTotalCnt) *
                                    1000
                                ) / 10
                              )
                            : 0
                        }%`}</Typography>
                      </Box>
                    </Box>
                  </Stack>
                </Paper>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 10 }}></Box>
      {prodStatistics.length > 0 && (
        <>
          <Box sx={{ mt: 10 }}></Box>
          <Box>
            <Typography sx={{ fontSize: "1rem", fontWeight: "700" }}>상품 통계</Typography>
          </Box>
          <Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>상품명</TableCell>
                    <TableCell align="center">상품클릭(시청자)</TableCell>
                    <TableCell align="center">상품클릭수(누적)</TableCell>
                    <TableCell align="center">상품클릭율</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {prodStatistics.map((row: any, index: number) => (
                    <TableRow key={`prod-list-${index}`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        <Box>
                          <Stack direction={"row"} spacing={1}>
                            <Box>
                              <CardMedia
                                component="img"
                                image={row.goodsImgPath}
                                sx={{ width: "64px", height: "64px", objectFit: "contain", borderRadius: "7px" }}
                              />
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Stack direction={"column"} spacing={2}>
                                <Box>{row.goodsNm}</Box>
                                <Box>{`${cUtils.numericComma(row.salePrc)}원`}</Box>
                              </Stack>
                            </Box>
                          </Stack>
                        </Box>
                      </TableCell>
                      <TableCell align="center" sx={{ width: "120px" }}>
                        {cUtils.numericComma(row.clickUsers.length)}
                      </TableCell>
                      <TableCell align="center" sx={{ width: "120px" }}>
                        {cUtils.numericComma(row.clickCnt)}
                      </TableCell>
                      <TableCell align="center" sx={{ width: "120px" }}>{`${!isNaN(row.clickRate) ? row.clickRate : 0}%`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
      <Toast ref={toastRef} />
    </Box>
  );
};

export default StatisticInfo;
