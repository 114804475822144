import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import { userState } from "../../interface/MainInterface";

interface propsType {
  userState: userState;
}

const MainPage = (props: propsType) => {
  let navigate = useNavigate();
  const fncSignUp = useCallback(() => {
    navigate("/signup");
  }, [navigate]);

  const fncSignIn = useCallback(() => {
    navigate("/signin");
  }, [navigate]);

  useEffect(() => {
    if (props.userState.isAuth) window.location.href = "/list";
  }, []);

  return (
    <>
      {!props.userState.isAuth && (
        <>
          <Box>
            <Box sx={{ p: 0 }}>
              <Stack spacing={0} direction="column">
                {/* Title */}
                <Box component={"div"} className="main-video">
                  <video loop muted autoPlay playsInline>
                    <source src="https://thd-upload.livehyundai.com/bluegury@hyundai-ite.com/c9059fed-ae74-4e13-93bd-54e589af9499/source-video/31f2ff0f-f20e-4ee4-bf9d-7f15a1167111.mp4" />
                  </video>
                  <div className="main-copy">
                    <dl>
                      <dt className="roboto fw-300 wow fadeInUp animated color-white fs-normal ta-center" data-wow-delay="0.2s">
                        Digital Innovation Leader
                      </dt>
                      <dd className="roboto fw-700 wow fadeInUp animated color-white fs-big ta-center ml-0" data-wow-delay="0.4s">
                        THE HYUNDAI Live admin
                      </dd>
                      <dd>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Button variant="contained" color="error" sx={{ width: "200px", fontSize: "20px" }} onClick={fncSignIn}>
                            Log In
                          </Button>
                        </Box>
                      </dd>
                    </dl>
                  </div>
                </Box>
                {/* Title */}
              </Stack>
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "10px",
              right: "20px",
            }}
          >
            <Stack spacing={2} direction="row">
              <Button variant="text" onClick={fncSignIn}>
                Log In
              </Button>
              {/* <Button variant="contained" onClick={fncSignUp}>
                Sign Up
              </Button> */}
            </Stack>
          </Box>
        </>
      )}
    </>
  );
};

export default MainPage;
